let domainAnalysisComplete = false;
let webAppProtocol = "";
let webAppSubdomain = "";
let truncatedHostname = "";
let webAppPort = "";

const domainAnalysis = (): void => {
  if (domainAnalysisComplete === false) {
    const {
      protocol: INITIAL_PROTOCOL,
      hostname: INITIAL_HOSTNAME,
      port: INITIAL_PORT,
    } = window.location;
    webAppProtocol = INITIAL_PROTOCOL;
    webAppPort = INITIAL_PORT;
    const hostnameComponents = INITIAL_HOSTNAME.split(".");
    const numHostnameComponents = hostnameComponents.length;
    let siteWasAccessedThroughSubdomain = false;
    if (numHostnameComponents > 3) {
      siteWasAccessedThroughSubdomain = true;
    }

    webAppSubdomain = "";
    truncatedHostname = "";
    for (let i = 0; i < (numHostnameComponents - 3); i += 1) {
      webAppSubdomain = `${webAppSubdomain}${(i === 0) ? "" : "."}${hostnameComponents[i]}`;
    }
    for (let i = (numHostnameComponents - 3); i < numHostnameComponents; i += 1) {
      truncatedHostname = `${truncatedHostname}${(i === (numHostnameComponents - 3)) ? "" : "."}${hostnameComponents[i]}`;
    }

    domainAnalysisComplete = true;
  }
};

export const WebAppProtocol = (): string => {
  domainAnalysis();
  return webAppProtocol;
};

export const WebAppSubdomain = (): string => {
  domainAnalysis();
  return webAppSubdomain;
};

export const TruncatedHostname = (): string => {
  domainAnalysis();
  return truncatedHostname;
};

export const WebAppPort = (): string => {
  domainAnalysis();
  return webAppPort;
};

export const getCurrentTeamName = (): string => {
  return WebAppSubdomain() || "";
  // return "Kraken";
};
