import styled from "styled-components";
import { Link } from "react-router-dom";
import { COLORS } from "../../styled/basic";

export const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus, &:hover, &:visited, &:link, &:active {
    text-decoration: none;
  }
`;

export const NavigationBlock = styled.div`
  display: flex;
  flex-direction: row;
  height: 50px;
  background-color: ${COLORS.BLACK_MAIN};
  color: black;
  width: 100%;
  padding: 5px 5px;
  user-select: none;
  border-bottom-width: 2px;
  border-bottom-color: ${COLORS.BLUE_LIGHT};
  border-bottom-style: solid;
`;

interface NavigationItemProps {
  active?: boolean;
  fontFamily?: string;
}

export const NavigationItem = styled.div<NavigationItemProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-size: 23px;
  font-family: ${({ fontFamily }) => fontFamily || "Seahawks"};
  color: ${COLORS.WHITE};
  height: 100%;
  width: 100px;
  padding-top: 10px;
  border-right-width: 1px;
  border-right-color: ${COLORS.BLUE_LIGHT};
  border-right-style: solid;

  text-decoration: ${({ active }) => (active ? "underline" : "none")};

  :hover {
    background: ${COLORS.BLUE_LIGHT};
  }

  transition: all 0.3s ease-in-out;
`;

export const AvatarBlock = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-content: center;
`;

export const AvatarArea = styled.div`
  width: 32px;
  height: 32px;
  cursor: pointer;
`;
