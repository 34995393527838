// eslint-disable-file max-len
import { NameInput } from "../shared/interfaces";

export interface UserRightsType {
  isAdmininstrator: boolean;
  teams: string[];
  stadiums: string[];
  operatorForStadiums: string[];
}

export const getEmptyUserRights = (): UserRightsType => {
  return {
    isAdmininstrator: false,
    teams: [],
    stadiums: [],
    operatorForStadiums: [],
  };
};

const SUPER_ADMINISTRATOR_GROUP = "superadministrators";
const ADMINISTRATOR_GROUP = "administrators";
const TEAM_GROUP_START = "teamAdmin_";
const STADIUM_GROUP_START = "stadiumAdmin_";
const STADIUM_OPERATOR_GROUP_START = "stadiumOperator_";

export const parseUserRights = (groups: string[]): UserRightsType => {
  const rights = getEmptyUserRights();

  for (let ind = 0, len = groups.length; ind < len; ind += 1) {
    const group = groups[ind];
    if (group === SUPER_ADMINISTRATOR_GROUP || group === ADMINISTRATOR_GROUP) {
      rights.isAdmininstrator = true;
    } else if (group.startsWith(TEAM_GROUP_START)) {
      const team = group.substring(TEAM_GROUP_START.length, group.length);
      rights.teams.push(team);
    } else if (group.startsWith(STADIUM_GROUP_START)) {
      const stadium = group.substring(STADIUM_GROUP_START.length, group.length);
      rights.stadiums.push(stadium);
    } else if (group.startsWith(STADIUM_OPERATOR_GROUP_START)) {
      const stadium = group.substring(STADIUM_OPERATOR_GROUP_START.length, group.length);
      rights.operatorForStadiums.push(stadium);
    }
  }

  console.log({ ...rights }, groups);
  return rights;
};

export const games_canViewGamesPage = (userRights: UserRightsType): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  if (userRights.stadiums.length || userRights.operatorForStadiums.length
    || userRights.teams.length) {
    return true;
  }

  return false;
};

export const games_canAddGame = (userRights: UserRightsType): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  if (userRights.stadiums.length || userRights.operatorForStadiums.length) {
    return true;
  }

  return false;
};

export const games_canViewContactInfoFromGamesBelow = (userRights: UserRightsType): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  return false;
};

export const games_canViewAnalyticsFromGamesBelow = (userRights: UserRightsType): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  return false;
};

export const games_canEditGame = (userRights: UserRightsType, stadium: string): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  if (userRights.stadiums.some((s) => s === stadium)) {
    return true;
  }

  return false;
};

export const games_canEditGameMoments = (userRights: UserRightsType, stadium: string): boolean => {
  if (userRights.isAdmininstrator) {
    return true;
  }

  if (userRights.stadiums.some((s) => s === stadium)) {
    return true;
  }

  if (userRights.operatorForStadiums.some((s) => s === stadium)) {
    return true;
  }

  return false;
};

export const rights_filterStadiums = (
  userRights: UserRightsType, stadiums: NameInput[],
): NameInput[] => {
  if (userRights.isAdmininstrator) {
    return stadiums;
  }

  const available = stadiums.filter((stadium) => {
    return userRights.stadiums.some((s) => s === stadium.name);
  });

  return available;
};

/* eslint-disable max-len */
/*
TODO LIST FOR USER ROLES:
  - Store session in LocalStorage instead of explicit cookies (session.ts)
  - Watch over token expiration using timer (session.ts)
  - Decide if we need in the cognito token on the backend side (uncomment setToken and add backend code)
  - Check if we correctly sign user out (maybe backend endpoint?)
  - Completely define all roles and naming conception (discuss)
  - Retreive roles list in session.ts (session.ts, backend code)
  - Expand groups applying to Moments, Teams, Stadiums, Mapping, Users pages (pages)
  - Add Avatar (session.ts, navigation)
  - Clean code

  Currently groups can be used on the Games page only:
  Games Page:
    - Any user from SuperAdministrators, Administrators, any TeamAdmin_, any StadiumAdmin_, any StadiumOperator_ group can view this screen and list any games to investigate details.
    - No groups: no access
    - Only Admins can see View Contact Info From Games Below and View Analytics From Games Below buttons
    - Only Admins and StadiumAdmins can see Add Game button. StadiumAdmins are restricted with theirs stadiums only.
    - Only Admins and StadiumAdmins can edit different games details: edit, delete, change status. StadiumAdmins are restricted with theirs stadium's games only.
    - Only Admins and StadiumAdmins and StadiumOperators can edit Game moments. They are restricted with games on theirs stadiums only.
*/
