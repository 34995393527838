import { makeAutoObservable, observable } from "mobx";
import { createContext } from "react";

import * as Request from "../axios/routes/users";
import {
  UniqueUser,
  PersistedUser,
  GetCognitoUserResponse,
  CognitoTokenRefreshRequest,
  CognitoTokenRefreshResponse,
  CognitoTokenResponse,
} from "../shared/interfaces";
import { DEFAULT_SELECT_VALUE } from "../utils/constants";
import { TableTag } from "../interfaces/base";
import { NotificationServiceInstance } from "./notification";

class UsersService {
  @observable users: UniqueUser[] = [];

  @observable appTokenSet = false;

  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  private init = async () => {
    if (!this.appTokenSet) {
      console.log("> Token is not set yet, waiting for 300ms");
      setTimeout(() => {
        this.init();
      }, 300);
    }
  }

  public onGamesSpecified = (selectedGames: string[]) => {
    console.log("UsersService.onGamesSpecified()");
    this.fetchUserList(selectedGames);
  }

  public fetchUserList = async (selectedGames: string[]): Promise<UniqueUser[]> => {
    this.users = [];
    try {
      const users = await Request.getUserListRequest({
        games: selectedGames,
      });

      this.users = users;
      return users;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchUserList()", err.message);
      return [];
    }
  }

  public getUserById = async (userId: string): Promise<PersistedUser|null> => {
    try {
      return await Request.getUserByIdRequest(userId);
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("getUserById()", err.message);
      return null;
    }
  }

  /*
  public deleteUser = async (_id: string) => {
    try {
      if (await Request.deleteUserRequest(_id)) {
        this.fetchUserList();
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("deleteUser()", err.message);
    }
  }

  public createUser = async (request: FormData): Promise<boolean> => {
    try {
      const { _id } = await Request.addUserRequest(request);
      if (!_id) return false;

      const newUser = await Request.getUserByIdRequest(_id);
      this.users.unshift({
        ...newUser,
        tag: "new",
      });

      return true;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("createUser()", err.message);
      return false;
    }
  }

  public updateUser = async (request: FormData, _id: string): Promise<boolean> => {
    try {
      if (await Request.updateUserRequest(request, _id)) {
        const updatedUser = await Request.getUserByIdRequest(_id);
        const index = this.users.findIndex((t) => t._id === _id);
        if (index !== -1) {
          this.users.splice(index, 1, {
            ...updatedUser,
            tag: "updated",
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateUser()", err.message);
      return false;
    }
  }
  */

  public getCognitoTokens = async (cognitoCode?: string, redirect_uri?: string):
    Promise<CognitoTokenResponse> => {
    try {
      return await Request.exchangeCognitoCodeForTokensRequest(cognitoCode, redirect_uri);
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("getCognitoTokens()", err.message);
      return null;
    }
  }

  public refreshCognitoTokens = async (request: CognitoTokenRefreshRequest):
    Promise<CognitoTokenRefreshResponse> => {
    const response = await Request.refreshCognitoTokensRequest(request);
    return response;
  }

  public getCognitoUser = async (emailAddress?: string, accessToken?: string):
    Promise<GetCognitoUserResponse> => {
    try {
      return await Request.getCognitoUserRequest(emailAddress, accessToken);
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("getCognitoUser()", err.message);
      return null;
    }
  }

  // not used
  public updateUserImagesLinks = async (
    links,
  ) => {
    console.log("start with", links);
    this.users = this.users.map((user) => {
      return links[user._id] ? { ...user, unique_url: links[user._id] } : user;
    });
    console.log("finish");
  }
}

export const UsersServiceInstance = new UsersService();

export const UsersServiceContext = createContext(UsersServiceInstance);
