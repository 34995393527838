import { makeAutoObservable, observable } from "mobx";
import { createContext } from "react";

import { TableTag } from "../interfaces/base";
import { AddStadiumResult } from "../axios/interfaces";
import { NameInput, StadiumAggregated } from "../shared/interfaces";

import * as Request from "../axios/routes/stadium";
import * as SportRequest from "../axios/routes/sport";

import { AppServiceInstance } from "./app";
import { DEFAULT_SELECT_VALUE } from "../utils/constants";
import { NotificationServiceInstance } from "./notification";

export interface StadiumPopulatedWithTag extends StadiumAggregated, TableTag {}

class StadiumsService {
  @observable cityList: NameInput[] = [];

  @observable stadiums: StadiumPopulatedWithTag[] = [];

  @observable selectedState: string = null;

  @observable selectedCity: string = null;

  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  private init = async () => {
    if (!AppServiceInstance.token) {
      console.log("> Token is not set yet, waiting for 300ms");
      setTimeout(() => {
        this.init();
      }, 300);
      return;
    }
    this.fetchStadiumList();
  }

  private fetchCityList = async () => {
    try {
      this.cityList = await SportRequest.getCityListRequest({ state: this.selectedState });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchStadiumList()", err.message);
    }
  }

  private fetchStadiumList = async () => {
    try {
      this.stadiums = await Request.getStadiumPopulatedListRequest({
        state: this.selectedState,
        city: this.selectedCity,
      });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchStadiumList()", err.message);
    }
  }

  public onStateChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedState = null;
      this.cityList = [];
    } else {
      this.selectedState = _id;
      this.fetchCityList();
    }
    this.selectedCity = null;
    this.fetchStadiumList();
  }

  public onCityChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedCity = null;
    } else {
      this.selectedCity = _id;
    }
    this.fetchStadiumList();
  }

  public deleteStadium = async (_id: string) => {
    try {
      if (await Request.deleteStadiumRequest(_id)) {
        this.fetchStadiumList();
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("deleteStadium()", err.message);
    }
  }

  public createStadium = async (request: FormData): Promise<AddStadiumResult> => {
    try {
      const { _id, imageUploadURL } = await Request.addStadiumRequest(request);
      if (!_id) return null;

      const newStadium = await Request.getStadiumById(_id);
      this.stadiums.unshift({
        ...newStadium,
        tag: "new",
        homeTeams: [],
      });

      return { _id, imageUploadURL };
    } catch (err) {
      console.log("createStadium() error: ", err);
      NotificationServiceInstance.showUnexpectedErrorNotification("createStadium()", err.message);
      return null;
    }
  }

  public updateStadium = async (
    request: FormData,
    _id: string,
  ): Promise<AddStadiumResult> => {
    let updateStadiumServiceResult = null;
    try {
      updateStadiumServiceResult = await Request.updateStadiumRequest(request, _id);
      if (updateStadiumServiceResult) {
        const updatedStadium = await Request.getStadiumById(_id);
        const index = this.stadiums.findIndex((s) => s._id === _id);
        if (index !== -1) {
          this.stadiums.splice(index, 1, {
            ...updatedStadium,
            tag: "updated",
            homeTeams: this.stadiums[index].homeTeams,
          });
        }
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateStadium()", err.message);
    }
    return updateStadiumServiceResult;
  }
}

export const StadiumsServiceInstance = new StadiumsService();

export const StadiumsServiceContext = createContext(StadiumsServiceInstance);
