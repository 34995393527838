import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { Avatar, Dropdown, Menu } from "antd";
import { COLORS, FontFamilyTypeEnum } from "../../styled/basic";
import Logo from "../../assets/images/MomentoHeader.png";
import { MomentServiceInstance } from "../../services/moments";
import { NotificationServiceInstance } from "../../services/notification";
import { Routes } from "../../utils/routes";
import { StyledLink, NavigationBlock, NavigationItem, AvatarBlock, AvatarArea } from "./styled";
import { SessionContext, SessionServiceInstance } from "../../services/session";
import { CognitoHostedUILogoutURL } from "../../pages/public/login";

const EmailText = styled.span`
  margin-right: 10px;
  align-self: center;
  white-space: nowrap;
  color: ${COLORS.BLUE_LIGHT};
  font-weight: 650;
  font-family: ${FontFamilyTypeEnum.SFPRO};
  // Add more styles as needed, such as font size, color, etc.
`;

export const NavigationComponent = (): JSX.Element => {
  const { pathname } = useLocation();
  const { EmailAddress } = useContext(SessionContext);

  const resetMomentPage = () => {
    MomentServiceInstance.clearData();
    NotificationServiceInstance.closeUpdatedMomentNotification();
  };

  const onLogout = async () => {
    console.log("on logout");
    await SessionServiceInstance.clearSession();
    window.location.href = CognitoHostedUILogoutURL();
  };

  const menu = (
    <Menu>
      <Menu.Item disabled>
        {EmailAddress || "No Email"}
      </Menu.Item>
      <Menu.Item onClick={onLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <NavigationBlock>
      <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
        <img src={Logo} alt="Company Logo" style={{ height: "100%" }} />
      </div>
      <StyledLink to={Routes.GAMES} onClick={resetMomentPage}>
        <NavigationItem fontFamily="Seahawks" active={pathname.includes(Routes.GAMES)}>Games</NavigationItem>
      </StyledLink>
      <StyledLink to={Routes.TEAMS} onClick={resetMomentPage}>
        <NavigationItem fontFamily="Seahawks" active={pathname.includes(Routes.TEAMS)}>Teams</NavigationItem>
      </StyledLink>

      {/* Avatar and email container */}
      <AvatarBlock>
        {/* Email text added here */}
        <EmailText>{EmailAddress}</EmailText>

        {/* Dropdown menu with avatar */}
        <Dropdown overlay={menu}>
          <AvatarArea>
            <Avatar alt="User Avatar" />
          </AvatarArea>
        </Dropdown>
      </AvatarBlock>
    </NavigationBlock>
  );
};
