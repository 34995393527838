import { DocumentInput } from "./general";
import { GeneralMomentClient, OperatorMoment } from "./moment";

export enum ACTIONS {
  JOIN_OPERATOR_LOBBY = "JOIN_OPERATOR_LOBBY",
}

export const OPERATOR_LOBBY = "OPERATOR_LOBBY";

export enum MOMENT_EMITTER_ACTIONS {
  MOMENT_CREATED = "MOMENT_CREATED",
  GENERAL_MOMENT_CREATED = "GENERAL_MOMENT_CREATED",
  GENERAL_MOMENT_UPDATED = "GENERAL_MOMENT_UPDATED",
}

export enum ERROR {
  UNKNOWN,
}

export interface ErrorData {
  errorId: ERROR;
  error?: any;
}

export interface MomentCreatedEvent {
  momentId: string;
  stadiumId: string;
}

export interface MomentCreatedEventResponse {
  game: DocumentInput;
  moment: OperatorMoment;
}

export interface GeneralMomentCreatedEvent {
  generalMomentId: string;
  stadiumId: string;
}

export interface GeneralMomentCreatedEventResponse {
  generalMoment: GeneralMomentClient;
  game: DocumentInput;
}

export interface GeneralMomentUpdatedEvent {
  generalMomentId: string;
  momentId: string;
}

export interface GeneralMomentUpdatedEventResponse {
  moment: OperatorMoment;
  generalMomentId: DocumentInput;
}
