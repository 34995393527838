import { DocumentInput, NameInput } from "./general";

export enum TEMPLATE_TYPES {
  MAIN = "ticketTemplate",
  FAN1st = "fan1stTicketTemplate",
  SPECIAL_SECTION = "specialSectionTicketTemplate",
}

export interface GameInput {
  team1: string;
  team2: string;
  stadium: string;
  league: string;
  startDate: number;
  finishDate?: number;
  archived?: boolean;

  ticketTemplate?: string;
  template?: TicketTemplate;
  ticketTemplateImageKey?: string;
  fan1stTicketTemplate?: string;
  fan1stTemplate?: TicketTemplate;
  fan1stTicketTemplateImageKey?: string;
  specialSectionTicketTemplate?: string;
  specialSectionTemplate?: TicketTemplate;
  specialSectionTicketTemplateImageKey?: string;

  paidGame?: boolean;
  showPrintOrderButton?: boolean;
  checkLastName?: boolean;
}

export interface UpdateGameRequest {
  team1?: string;
  team2?: string;
  stadium?: string;
  league?: string;
  startDate?: number;
  archived?: boolean;

  ticketTemplate?: string;
  template?: TicketTemplate;
  ticketTemplateImageKey?: string;
  fan1stTicketTemplate?: string;
  fan1stTemplate?: TicketTemplate;
  fan1stTicketTemplateImageKey?: string;
  specialSectionTicketTemplate?: string;
  specialSectionTemplate?: TicketTemplate;
  specialSectionTicketTemplateImageKey?: string;

  paidGame?: boolean;
  showPrintOrderButton?: boolean;
  checkLastName?: boolean;
}

export interface SetFinishDateRequest {
  finishDate?: number;
  archived?: boolean;
}

export interface GetIntervalGames {
  start: number;
  recentGames?: boolean;
  count?: number;
  archived?: boolean | string;
}

export interface IntervalGamePopulated extends DocumentInput {
  state: string;
  city: string;
  stadium: string;
  stadiumSchema?: string;
  startDate: number;
  team1: TeamGamePopulated;
  team2: TeamGamePopulated;
  finishDate?: number;
  archived?: boolean;
  stadiumId: string;
  sportName?: string;

  lastMomentTeam1Score?: number;
  lastMomentTeam2Score?: number;
  lastMomentTime?: string;
  lastMomentPeriodType?: string;
  lastMomentPeriodText?: string;

  ticketTemplate?: string;
  template?: TicketTemplate;
  ticketTemplateImageKey?: string;
  fan1stTicketTemplate?: string;
  fan1stTemplate?: TicketTemplate;
  fan1stTicketTemplateImageKey?: string;
  specialSectionTicketTemplate?: string;
  specialSectionTemplate?: TicketTemplate;
  specialSectionTicketTemplateImageKey?: string;

  paidGame?: boolean;
  showPrintOrderButton?: boolean;
  checkLastName?: boolean;
}

export interface TeamGamePopulated {
  _id: string;
  name: string;
  logo: string;
  primaryColor?: string;
  paywallImage?: string;
  ticketAdImage?: string;
  popupImage?: string;
  hidePurchase?: boolean;
  showOrderPrintedPhoto?: boolean;
}

export interface TeamInputOperator extends DocumentInput, TeamGamePopulated {}

export interface OperatorGamePopulated extends DocumentInput {
  team1: TeamInputOperator;
  team2: TeamInputOperator;
  state: NameInput;
  city: NameInput;
  stadium: NameInput;
  sport: NameInput;
  league: NameInput;
  startDate: number;
  finishDate?: number;
  archived?: boolean;
  draftMoments?: number;

  ticketTemplate?: string;
  template?: TicketTemplate;
  ticketTemplateImageKey?: string;
  fan1stTicketTemplate?: string;
  fan1stTemplate?: TicketTemplate;
  fan1stTicketTemplateImageKey?: string;
  specialSectionTicketTemplate?: string;
  specialSectionTemplate?: TicketTemplate;
  specialSectionTicketTemplateImageKey?: string;

  paidGame?: boolean;
  showPrintOrderButton?: boolean;
  checkLastName?: boolean;
}

export interface GetGameListRequest {
  sport?: string;
  state?: string;
  city?: string;
  league?: string;
  stadium?: string;
  team?: string;
  date?: number;
  archived?: boolean | string;
}

export interface TemplateTextSection {
  hidden?: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
  backgroundColor?: string;
  color?: string;
  font?: string;
  fontSize?: string;
  fontBold?: string;
}

export interface TemplateImageSection {
  hidden?: boolean;
  x: number;
  y: number;
  width: number;
  height: number;
}

export interface TicketTemplateFields {
  team?: string;
  name: string;
  templateImageKey: string;
  templateImage?: string;
  imagePosition?: TemplateImageSection;
  imageScale?: number;
  section?: TemplateTextSection;
  row?: TemplateTextSection;
  seat?: TemplateTextSection;
  scoreTeam1?: TemplateTextSection;
  scoreTeam2?: TemplateTextSection;
  momentText?: TemplateTextSection;
  date?: TemplateTextSection;
  stadium?: TemplateTextSection;
  city?: TemplateTextSection;
  team1Image?: TemplateImageSection;
  team2Image?: TemplateImageSection;
}

export interface TicketTemplate extends TicketTemplateFields {
  _id?: string;
}

export interface OperatorGameSeat extends DocumentInput {
  section?: string;
  row?: string;
  seat?: string;
  lastName?: string;
  gameId?: string;
  linkedSeats?: string[];
}

export interface SeatInput {
  section?: string;
  row?: string;
  seat?: string;
  lastName?: string;
  gameId?: string;
  linkedSeats?: string[];
}
