export interface DocumentInput {
  _id: string;
}

export interface NameInput extends DocumentInput {
  name: string;
}

export enum UploadType {
  STADIUM,
  TEAM,
  CAMERA,
  OVERLAY,
  ADVERTISMENT,
  MOMENT,
  MOTG,
  PURCHASE_LINK,
  TICKET_TEMPLATE,
}

export interface BasicResponse {
  success: boolean;
  message: string;
}

export interface UploadURLResult {
  uploadKey: string;
  uploadURL: string;
}
