import { PARAMS, Routes } from "../utils/routes";

class NavigationService {
  /**
   * history object that can be used later for transfering between the pages
   * outside of components
   * Create a new method that uses the history object's method to utilize
   * this functionality
   */
  private history = null;

  public setHistory = (history: any) => {
    this.history = history;
  }

  public goToGameMoments = (gameId: string) => {
    this.history.push({
      pathname: Routes.MOMENT,
      search: `${PARAMS.GAME_ID}=${gameId}`,
    });
  }
}

export const NavigationServiceInstance = new NavigationService();
