import styled from "styled-components";
import { COLORS, Z_INDEX } from "./basic";

export const FormContentWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
`;

export const FormWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const FormRow = styled.div`
  display: flex;
  flex-direction: row;
  padding: 10px 10px 0px 10px;
`;

export const FormColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

interface FormInputsWrapperProps {
  fullWidth?: boolean;
}

export const FormInputsWrapper = styled.div<FormInputsWrapperProps>`
  display: flex;
  flex-direction: column;
  width: 60%;
  width: ${({ fullWidth }) => (fullWidth ? "100%" : "60%")};
`;

interface FormLogoWrapperProps {
  flexStart?: boolean;
}

export const FormLogoWrapper = styled.div<FormLogoWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ flexStart }) => (flexStart ? "flex-start" : "center")};
  align-items: center;
  width: 40%;
`;

export const AdditionalImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  max-width: calc(40% - 10px);
  margin: 5px;

  @media (max-width: 1100px) {
    flex-direction: column;
  }
`;

export const AdButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px;

  @media (max-width: 1100px) {
    align-items: flex-start;
    padding-left: 5px;
  }
`;

export const ColorsButtonsRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 2px 5px 2px 5px;
`;

export const ColorPickerArea = styled.div`
  position: absolute;
  right: 0;
  width: 220px;
  height: 330px;
  top: calc(50% - 160px);
  background-color: white;
  z-index: 99999;
`;

export const ButtonElement = styled.div`
  padding: 0px 5px 0px 5px;
`;

export const FormLogo = styled.div`
  margin-bottom: 20px;
`;

interface ToolBoxProps {
  justifyContent?: "space-between"|"center"|"flex-start"|"flex-end",
}

export const ToolBox = styled.div<ToolBoxProps>`
  display: flex;
  flex-direction: row;
  justify-content: ${({ justifyContent }) => justifyContent || "center"};

  & > * {
    margin: 0 3px;
  }
`;

interface UploadLabelProps {
  width?: string;
  height?: string;
}

export const UploadLabel = styled.label<UploadLabelProps>`
  width: ${({ width }) => (width || "120px")};
  height: ${({ height }) => (height || "39px")};
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

export const UploadInfo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
`;

export const FormToolbox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  & > * {
    margin-left: 10px;
  }
`;

export const FormPartLabel = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);

  border-width: 1px;
  border-style: solid;
  border-color: ${COLORS.BLUE_LIGHT};
  border-radius: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;

  background-color: ${COLORS.WHITE};
  color: ${COLORS.BLACK_MAIN};
  z-index: ${Z_INDEX.MIDDLE};

  font-size: 11px;
  line-height: 15px;
`;

export const GeneratePreviewImagesContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 10px 10px 10px;
`;

export const GenerateStatusLine = styled.div`
  display: flex;
  flex-direction: row;
`;
