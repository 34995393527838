import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { Switch, Redirect, Route, useLocation, useHistory } from "react-router-dom";
import { configure } from "mobx";

import { parse } from "query-string";
import { Input, Modal, Form } from "antd";
import { observer } from "mobx-react";
import { SuspenceFallbackComponent } from "./components/SuspenceFallback";
import { NavigationComponent } from "./components/Navigation";
import { CustomRoute } from "./components/CustomRoute";
import { PARAMS, Routes } from "./utils/routes";

import { BasicButton, BasicContentWrapper, COLORS } from "./styled/basic";
import { FormToolbox } from "./styled/form";

import "react-calendar/dist/Calendar.css";
import "./styles/main.scss";

import { AppServiceContext } from "./services/app";
import { NavigationServiceInstance } from "./services/navigation";
import "./services/teams";
import "./services/stadiums";
import "./services/game";
import "./services/socket";
import "./services/notification";

configure({
  enforceActions: "never",
});

const LoginPage = lazy(() => import("./pages/public/login"));
const GamesPage = lazy(() => import("./pages/games"));
const TeamsPage = lazy(() => import("./pages/teams"));
const StadiumPage = lazy(() => import("./pages/stadiums"));
const MappingPage = lazy(() => import("./pages/mapping"));
const MomentPage = lazy(() => import("./pages/moment"));
const UsersPage = lazy(() => import("./pages/users"));
const GameSeatsPage = lazy(() => import("./pages/gameSeats"));
const TemplatesPage = lazy(() => import("./pages/templates"));
const DownloadsPage = lazy(() => import("./pages/downloads"));

export const App = observer((): JSX.Element => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  useEffect(() => {
    NavigationServiceInstance.setHistory(history);
  }, []);

  const { lastCameraId, lastCameraName } = useContext(AppServiceContext);
  const [mappingModal, setMappingModal] = useState(false);
  const [form] = Form.useForm<{id: string}>();

  const goToMapping = (id: string) => {
    setMappingModal(false);
    history.push(`${Routes.MAPPING}?${PARAMS.ID}=${id}`);
    form.resetFields();
  };

  const goToStadiums = () => {
    setMappingModal(false);
    history.push(Routes.STADIUM);
    form.resetFields();
  };

  useEffect(() => {
    if (pathname.includes(Routes.MAPPING)) {
      const parsed = parse(search);
      const id = parsed[PARAMS.ID];
      if (!id) {
        setMappingModal(true);
        history.goBack();
      }
    }
  }, [search, pathname]);

  return (
    <BasicContentWrapper padding="0">
      <Suspense fallback={<SuspenceFallbackComponent />}>
        <NavigationComponent />

        <Modal
          visible={mappingModal}
          title="Enter Camera Id to proceed to the mapping or go to Stadium page"
          width="50%"
          style={{ minWidth: "600px", maxWidth: "1000px" }}
          okText="Submit"
          footer={null}
          onCancel={() => setMappingModal(false)}
        >
          <Form form={form} onFinish={(values) => goToMapping(values.id)}>
            <Form.Item
              label="Camera Id"
              name="id"
              rules={[{ required: true, message: "Please, enter the Camera Id!" }]}
            >
              <Input />
            </Form.Item>
            <FormToolbox>
              {lastCameraId && lastCameraName && (
                <BasicButton
                  type="button"
                  color={COLORS.WHITE}
                  backgroundColor={COLORS.BLUE_MAIN}
                  onClick={() => goToMapping(lastCameraId)}
                  style={{ marginRight: "auto", marginLeft: 0 }}
                >
                  {`Go to last camera (${lastCameraName})`}
                </BasicButton>
              )}

              <BasicButton type="button" onClick={goToStadiums}>Go to Stadiums page</BasicButton>
              <BasicButton type="submit" color={COLORS.WHITE} backgroundColor={COLORS.BLUE_MAIN}>Proceed to mapping</BasicButton>

            </FormToolbox>
          </Form>
        </Modal>

        <Switch>
          <CustomRoute exact path={Routes.LOGIN} Component={LoginPage} />
          <CustomRoute exact path={Routes.GAMES} Component={GamesPage} />
          <CustomRoute exact path={Routes.TEAMS} Component={TeamsPage} />
          <CustomRoute exact path={Routes.STADIUM} Component={StadiumPage} />
          <CustomRoute exact path={Routes.MAPPING} Component={MappingPage} />
          <CustomRoute exact path={Routes.MOMENT} Component={MomentPage} />
          <CustomRoute exact path={Routes.USERS} Component={UsersPage} />
          <CustomRoute exact path={Routes.GAMESEATS} Component={GameSeatsPage} />
          <CustomRoute exact path={Routes.TEMPLATES} Component={TemplatesPage} />
          <CustomRoute exact path={Routes.DOWNLOADS} Component={DownloadsPage} />
        </Switch>
      </Suspense>
    </BasicContentWrapper>
  );
});
