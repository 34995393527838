import { action, makeAutoObservable, observable } from "mobx";
import { createContext } from "react";

import * as SportRequest from "../axios/routes/sport";
import {
  addGameRequest, changeGameStatusRequest, deleteGameRequest,
  getGameDataByIdRequest, getGamesPopulatedRequest, updateGameIsPaidGameRequest,
  updateGameRequest, updateGameTemplateRequest, updateGameShowPrintOrderButtonRequest,
  updateGameTemplatesRequest,
  updateGameCheckLastNameRequest,
} from "../axios/routes/games";
import { TableTag } from "../interfaces/base";
import { GameInput, NameInput, OperatorGamePopulated, TeamPopulated, UpdateGameRequest } from "../shared/interfaces";
import { DEFAULT_SELECT_VALUE } from "../utils/constants";
import { getTeamListRequest } from "../axios/routes/teams";
import { GameStatus } from "../interfaces/game";
import { NotificationServiceInstance } from "./notification";

export interface GamePopulatedWithTag extends OperatorGamePopulated, TableTag {
  status: GameStatus,
}

class GameService {
  @observable games: GamePopulatedWithTag[] = [];

  // Dropdown data
  @observable leagueList: NameInput[] = [];

  @observable cityList: NameInput[] = [];

  @observable stadiumList: NameInput[] = [];

  @observable teamList: TeamPopulated[] = [];

  // Filter values
  @observable selectedDate: Date = null;

  @observable allTime = false;

  @observable selectedUnixDate: number = null;

  @observable selectedSport: string = null;

  @observable selectedLeague: string = null;

  @observable selectedState: string = null;

  @observable selectedCity: string = null;

  @observable selectedStadium: string = null;

  @observable selectedTeam: string = null;

  constructor() {
    makeAutoObservable(this);

    const date = new Date();

    this.selectedDate = date;
    this.selectedUnixDate = date.setHours(0, 0, 0, 0);

    this.fetchGames();
  }

  fetchGames = async () => {
    try {
      const response = await getGamesPopulatedRequest({
        city: this.selectedCity,
        league: this.selectedLeague,
        sport: this.selectedSport,
        stadium: this.selectedStadium,
        state: this.selectedState,
        team: this.selectedTeam,
        date: this.allTime ? null : this.selectedUnixDate,
      });

      this.games = response.map<GamePopulatedWithTag>((game) => ({
        ...game,
        status: this.calculateGameStatus(game),
      })).sort((a, b) => b.startDate - a.startDate);
    } catch (err) {
      NotificationServiceInstance.showErrorNotification(err.message);
    }
  }

  @action public updateDraftMoments = (gameId: string): GamePopulatedWithTag|null => {
    const targetGame = this.games.find((g) => g._id === gameId);
    if (!targetGame) {
      console.log("[new draft moment][game service] moment's game is not fetched");
      return null;
    }

    targetGame.draftMoments = targetGame.draftMoments ? targetGame.draftMoments + 1 : 1;

    console.log(`[new draft moment][game service] draft moments counter was updated. Current draft moments: ${targetGame.draftMoments}`);
    return targetGame;
  }

  @action public toggleDate = () => {
    this.allTime = !this.allTime;
    this.fetchGames();
  }

  @action public onDateChange = (date: Date) => {
    this.selectedDate = date;
    this.selectedUnixDate = date.setHours(0, 0, 0, 0);
    this.fetchGames();
  }

  @action public onSportChange = (_id: string) => {
    this.clearLeague();
    this.clearTeam();

    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedSport = null;
    } else {
      this.selectedSport = _id;
      this.fetchLeagueList();
    }
    this.fetchGames();
  }

  @action public onLeagueChange = (_id: string) => {
    this.clearTeam();

    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedLeague = null;
    } else {
      this.selectedLeague = _id;
      this.fetchTeamList();
    }
    this.fetchGames();
  }

  @action private clearLeague = () => {
    this.leagueList = [];
    this.selectedLeague = null;
  }

  @action public onTeamChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedTeam = null;
    } else {
      this.selectedTeam = _id;
    }
    this.fetchGames();
  }

  @action private clearTeam = () => {
    this.teamList = [];
    this.selectedTeam = null;
  }

  @action public onStateChange = (_id: string) => {
    this.clearCity();
    this.clearStadium();

    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedState = null;
    } else {
      this.selectedState = _id;
      this.fetchCityList();
    }
    this.fetchGames();
  }

  @action public onCityChange = (_id: string) => {
    this.clearStadium();

    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedCity = null;
    } else {
      this.selectedCity = _id;
      this.fetchStadiumList();
    }
    this.fetchGames();
  }

  @action private clearCity = () => {
    this.cityList = [];
    this.selectedCity = null;
  }

  @action public onStadiumChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedStadium = null;
    } else {
      this.selectedStadium = _id;
    }
    this.fetchGames();
  }

  @action private clearStadium = () => {
    this.selectedStadium = null;
    this.stadiumList = [];
  }

  @action public onReloadGames = () => {
    this.fetchGames();
  }

  private fetchLeagueList = async () => {
    try {
      this.leagueList = await SportRequest.getLeagueListRequest({ sport: this.selectedSport });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchSportList()", err.message);
    }
  }

  private fetchTeamList = async () => {
    try {
      this.teamList = await getTeamListRequest({
        league: this.selectedLeague, sport: this.selectedSport,
      });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchTeamList()", err.message);
    }
  }

  private fetchCityList = async () => {
    try {
      this.cityList = await SportRequest.getCityListRequest({ state: this.selectedState });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchStadiumList()", err.message);
    }
  }

  private fetchStadiumList = async () => {
    try {
      this.stadiumList = await SportRequest.getStadiumListRequest({
        city: this.selectedCity,
      });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchStadiumList()", err.message);
    }
  }

  public fetchGameById = async (gameId: string): Promise<OperatorGamePopulated|null> => {
    try {
      return await getGameDataByIdRequest(gameId);
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchGameById()", err.message);
      return null;
    }
  }

  public createGame = async (request: GameInput): Promise<boolean> => {
    try {
      // Ensure paidGame is set to false by default
      const newGameRequest = {
        ...request,
        paidGame: true,
      };

      const { _id } = await addGameRequest(newGameRequest);
      if (!_id) return false;

      const newGame = await getGameDataByIdRequest(_id);
      this.games.unshift({
        ...newGame,
        tag: "new",
        status: this.calculateGameStatus(newGame),
      });

      return true;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("createGame()", err.message);
      return false;
    }
  }

  public updateGame = async (request: UpdateGameRequest, _id: string): Promise<boolean> => {
    try {
      if (await updateGameRequest(request, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGame()", err.message);
      return false;
    }
  }

  public changeGameStatus = async (
    finishDate: number|null,
    archived: boolean,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (await changeGameStatusRequest({ finishDate, archived }, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("changeGameStatus()", err.message);
      return false;
    }
  }

  public deleteGame = async (_id: string) => {
    try {
      if (await deleteGameRequest(_id)) {
        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1);
        }
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("deleteGame()", err.message);
    }
  }

  private calculateGameStatus = (game: OperatorGamePopulated): GameStatus => {
    let status: GameStatus;

    if (game.archived) {
      status = "archived";
    } else if (game.finishDate) {
      status = "finished";
    } else if (game.startDate > Date.now()) {
      status = "upcoming";
    } else if (game.startDate < Date.now()) {
      status = "ongoing";
    }

    return status;
  }

  public updateGameTemplate = async (
    ticketTemplate: string,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (await updateGameTemplateRequest(ticketTemplate, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            // templates are expanded for getGameDataByIdRequest
            ticketTemplate: updatedGame.template?._id || "",
            fan1stTicketTemplate: updatedGame.fan1stTemplate?._id || "",
            specialSectionTicketTemplate: updatedGame.specialSectionTemplate?._id || "",
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGameTemplate()", err.message);
      return false;
    }
  }

  public updateGameTemplates = async (
    ticketTemplate: string,
    fan1stTicketTemplate: string,
    specialSectionTemplate: string,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (
        await updateGameTemplatesRequest(
          ticketTemplate,
          fan1stTicketTemplate,
          specialSectionTemplate,
          _id,
        )
      ) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            // templates are expanded for getGameDataByIdRequest
            ticketTemplate: updatedGame.template?._id || "",
            fan1stTicketTemplate: updatedGame.fan1stTemplate?._id || "",
            specialSectionTicketTemplate: updatedGame.specialSectionTemplate?._id || "",
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGameTemplates()", err.message);
      return false;
    }
  }

  public updateGameIsPaidGame = async (
    isPaid: boolean,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (await updateGameIsPaidGameRequest(isPaid, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            paidGame: !!updatedGame.paidGame,
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGameIsPaidGame()", err.message);
      return false;
    }
  }

  public updateGameShowPrintOrderButton = async (
    showPrintOrderButton: boolean,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (await updateGameShowPrintOrderButtonRequest(showPrintOrderButton, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            showPrintOrderButton: !!updatedGame.showPrintOrderButton,
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGameShowPrintOrderButton()", err.message);
      return false;
    }
  }

  public updateGameCheckLastName = async (
    checkLastName: boolean,
    _id: string,
  ): Promise<boolean> => {
    try {
      if (await updateGameCheckLastNameRequest(checkLastName, _id)) {
        const updatedGame = await getGameDataByIdRequest(_id);

        const index = this.games.findIndex((g) => g._id === _id);
        if (index !== -1) {
          this.games.splice(index, 1, {
            ...updatedGame,
            checkLastName: !!updatedGame.checkLastName,
            tag: "updated",
            status: this.calculateGameStatus(updatedGame),
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateGameCheckLastName()", err.message);
      return false;
    }
  }
}

export const GameServiceInstance = new GameService();

export const GameServiceContext = createContext(GameServiceInstance);
