import io from "socket.io-client";

import { ACTIONS, GeneralMomentCreatedEventResponse, GeneralMomentUpdatedEventResponse, MOMENT_EMITTER_ACTIONS } from "../shared/interfaces";
import { SERVER_BASE_URL } from "../utils/constants";
import { GameServiceInstance } from "./game";
import { MomentServiceInstance } from "./moments";
import { NotificationServiceInstance } from "./notification";

class SocketService {
  private socket: SocketIOClient.Socket

  constructor() {
    this.socket = io(SERVER_BASE_URL);

    this.socket.on("connect", () => {
      console.log(`[Socket][on: connect] Connected to the ${SERVER_BASE_URL}`);
      this.joinOperatorsRoom();
    });
  }

  private joinOperatorsRoom = (): void => {
    this.socket.emit(ACTIONS.JOIN_OPERATOR_LOBBY, {}, () => {
      console.log("[Socket][emit: JOIN_OPERATOR_LOBBY] Joined the operators' room");
      this.onGeneralMomentCreate();
      this.onGeneralMomentUpdate();
    });
  }

  private onGeneralMomentCreate = (): void => {
    this.socket.on(
      MOMENT_EMITTER_ACTIONS.GENERAL_MOMENT_CREATED,
      (data: GeneralMomentCreatedEventResponse) => {
        console.log("[Socket][on: GENERAL_MOMENT_CREATED] data: ", data);
        this.addNewGeneralMoment(data);
      },
    );
  }

  private onGeneralMomentUpdate = (): void => {
    this.socket.on(
      MOMENT_EMITTER_ACTIONS.GENERAL_MOMENT_UPDATED,
      (data: GeneralMomentUpdatedEventResponse) => {
        console.log("[Socket][on: GENERAL_MOMENT_UPDATED] data: ", data);
        this.updateGeneralMoment(data);
      },
    );
  }

  private addNewGeneralMoment = (data: GeneralMomentCreatedEventResponse): void => {
    const updatedGameFromList = GameServiceInstance.updateDraftMoments(data.game._id);

    const updatedCurrentGame = MomentServiceInstance.socketAddDraftGeneralMoment(
      data.game._id, data.generalMoment,
    );

    if (updatedCurrentGame) {
      NotificationServiceInstance.showInfoNotification("Current game just got a new moment", 5);
    } else if (updatedGameFromList) {
      NotificationServiceInstance.showDraftMomentNotification(updatedGameFromList);
    } else {
      NotificationServiceInstance.showInfoNotification("One of the games just got a new Moment. Check the game page for more information", 10);
    }
  }

  private updateGeneralMoment = (data: GeneralMomentUpdatedEventResponse): void => {
    MomentServiceInstance.socketUpdateGeneralMoment(
      data.generalMomentId._id, data.moment,
    );
  }
}

export const SocketServiceInstance = new SocketService();
