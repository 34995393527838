import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import { BasicResponse, GetOperatorMomentRequest, UpdateMomentRequest, GeneralMomentClient } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getGeneralMomentListRequest = async (
  request: GetOperatorMomentRequest,
): Promise<GeneralMomentClient[]> => {
  try {
    const response = await instance.post<GeneralMomentClient[]>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GENERAL}/${PATH.MOMENT}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getGeneralMomentRequest = async (
  generalMomentId: string,
): Promise<GeneralMomentClient> => {
  try {
    const response = await instance.get<GeneralMomentClient>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GENERAL}/${PATH.MOMENT}/${generalMomentId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGeneralMomentRequest = async (
  request: UpdateMomentRequest,
  generalMomentId: string,
): Promise<boolean> => {
  try {
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GENERAL}/${PATH.MOMENT}/${generalMomentId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
