import { stringify } from "query-string";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import {
  GetLeagueListRequest, NameInput, GetCityListRequest,
  GetStadiumListRequest, GetTeamListRequest, GetSportListRequest,
} from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getSportListRequest = async (): Promise<GetSportListRequest[]> => {
  try {
    const response = await instance.get<GetSportListRequest[]>(`${PATH.API}/${PATH.SPORT}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getLeagueListRequest = async (request: GetLeagueListRequest): Promise<NameInput[]> => {
  const params = stringify(request);

  try {
    const response = await instance.get(`${PATH.API}/${PATH.LEAGUE}?${params}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getTeamListRequest = async (request: GetTeamListRequest): Promise<NameInput[]> => {
  const params = stringify(request);

  try {
    const response = await instance.get(`${PATH.API}/${PATH.TEAM}/${PATH.LIST}?${params}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getStateListRequest = async (): Promise<NameInput[]> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.STATE}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getCityListRequest = async (request: GetCityListRequest): Promise<NameInput[]> => {
  const params = stringify(request);

  try {
    const response = await instance.get(`${PATH.API}/${PATH.CITY}?${params}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getStadiumListRequest = async (
  request: GetStadiumListRequest,
): Promise<NameInput[]> => {
  const params = stringify(request);

  try {
    const response = await instance.get(`${PATH.API}/${PATH.STADIUM}?${params}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
