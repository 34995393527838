import { stringify } from "query-string";
import { instance } from "../instance";
import { IAxiosError, AddStadiumResult } from "../interfaces";
import { getError } from "../utils";

import { DocumentInput, GetStadiumListAggregatedRequest, StadiumAggregated, StadiumPopulated } from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getStadiumPopulatedListRequest = async (
  request: GetStadiumListAggregatedRequest,
): Promise<StadiumAggregated[]> => {
  const stringified = request.city || request.state ? stringify(request, {
    skipNull: true,
  }) : null;

  try {
    const response = await instance.get<StadiumAggregated[]>(`${PATH.API}/${PATH.STADIUM}/${PATH.POPULATED}?${stringified}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getStadiumById = async (stadiumId: string): Promise<StadiumPopulated> => {
  try {
    const response = await instance.get<StadiumPopulated>(`${PATH.API}/${PATH.STADIUM}/${stadiumId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addStadiumRequest = async (request: FormData): Promise<AddStadiumResult> => {
  try {
    const response = await instance.post<AddStadiumResult>(`${PATH.API}/${PATH.OPERATOR}/${PATH.STADIUM}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    console.log("addStadiumRequest: ", err);
    throw new Error(getError(response));
  }
};

export const updateStadiumRequest = async (
  request: FormData, stadiumId: string,
): Promise<AddStadiumResult> => {
  try {
    const response = await instance.put<AddStadiumResult>(`${PATH.API}/${PATH.OPERATOR}/${PATH.STADIUM}/${stadiumId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const deleteStadiumRequest = async (stadiumId: string): Promise<boolean> => {
  try {
    await instance.delete<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.STADIUM}/${stadiumId}`);

    return true;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
