import { message } from "antd";
import { maxBy } from "lodash";
import { observable, makeAutoObservable, action, runInAction, computed } from "mobx";
import { createContext } from "react";
import { getGeneralMomentListRequest, getGeneralMomentRequest, updateGeneralMomentRequest } from "../axios/routes/generalMoment";
import {
  analyzeMomentsRequest,
  getMomentMomentoListRequest,
  getMomentSeatPreviewAllRequest,
  deleteMomentLinkRequest,
} from "../axios/routes/moment";

import {
  GeneralMomentClient, MomentStatus, OperatorGamePopulated,
  OperatorMoment,
  PreviewSeat, UpdateMomentRequest, AnalyzeMomentsRequest,
} from "../shared/interfaces";
import { isValidNumber } from "../utils/validation";
import { GameServiceInstance } from "./game";
import { NotificationServiceInstance } from "./notification";

export type MomentTag = "archived"|"published";

export interface GeneralMomentClientWithTag extends GeneralMomentClient {
  tag?: MomentTag;
}

export const getCamerasList = (moment: GeneralMomentClientWithTag): string[] => {
  if (!moment || !moment.moments || !moment.moments.length) {
    return [];
  }

  const existingCameras = {};
  return moment.moments.reduce((acc, m) => {
    const { camera } = m;
    if (!existingCameras[camera]) {
      existingCameras[camera] = true;
      acc.push(camera);
    }

    return acc;
  }, []);
};

export const getCamerasCount = (moment: GeneralMomentClientWithTag): number => {
  const list = getCamerasList(moment);

  return list.length;
};

class MomentService {
  @observable momentList: GeneralMomentClient[] = [];

  @observable momentoLinksLoading = false;

  @observable momentoLinks: string[] = [];

  @observable momentoPreviewLinks: string[] = [];

  @observable momentoFullSizeLinks: string[] = [];

  @observable selectedMoment: GeneralMomentClientWithTag = null;

  @observable selectedMomentCameraName: string = null;

  @observable momentViewerOpened = false;

  @observable momentGameData: OperatorGamePopulated = null;

  @observable previewTitle: string = null;

  @observable previewSeats: string[] = [];

  @observable seatPreviewLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Searches for the max score among published moments
   */
  @computed get latestPublishedMoment() {
    const publishedMoments = this.momentList.filter((m) => m.moments[0].status === "published");
    if (publishedMoments.length) {
      return maxBy(publishedMoments, (m) => m.startTime).moments[0];
    }
    return null;
  }

  /**
   * Cameras that operator can select from dropdown to preview image from specific camera
   */
  @computed get cameras() {
    return getCamerasList(this.selectedMoment);
  }

  public closeMomentViewer = () => { this.momentViewerOpened = false; }

  public openMomentViewer = () => { this.momentViewerOpened = true; }

  public selectMoment = (gMoment: GeneralMomentClient, cameraName?: string): void => {
    this.selectedMoment = gMoment;
    this.selectedMomentCameraName = cameraName;
    if (!cameraName) {
      if (gMoment?.moments?.length > 0) {
        this.selectedMomentCameraName = gMoment.moments[0].camera;
      }
    }

    this.fetchAllMomento();

    this.openMomentViewer();
  }

  public selectCamera = (cameraName: string): void => {
    this.selectedMomentCameraName = cameraName || null;
    this.fetchAllMomento();
  }

  public deleteImageFromMoment = async (link: string, index: number) : Promise<boolean> => {
    let itemRemoved = false;
    for (let ind = 0, len = this.selectedMoment.moments.length; ind < len; ind += 1) {
      const m = this.selectedMoment.moments[ind];
      if (m.camera === this.selectedMomentCameraName) {
        // eslint-disable-next-line no-await-in-loop
        const momentoListResponse = await deleteMomentLinkRequest(m._id, link);
        if (("itemRemoved" in momentoListResponse) && (momentoListResponse.itemRemoved === true)) {
          itemRemoved = true;
        }
      }
    }
    return itemRemoved;
  }

  @action public clearData = () => {
    this.momentList = [];

    this.momentoLinks = [];

    this.selectedMoment = null;
    this.selectedMomentCameraName = null;

    this.momentGameData = null;

    this.momentoLinksLoading = false;

    this.previewSeats = [];
    this.previewTitle = null;
  }

  public fetchMomentList = async (gameId: string) => {
    try {
      await this.fetchGameData(gameId);

      const response = await getGeneralMomentListRequest({
        from: this.momentGameData.startDate,
        to: this.momentGameData.finishDate || null,
        stadium: this.momentGameData.stadium._id,
      });

      console.info(response);

      runInAction(() => {
        this.momentList = response;
      });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchMomentList()", err.message);
    }
  }

  public clearMomentList = async () => {
    runInAction(() => {
      this.momentList = [];
    });
  }

  private fetchGameData = async (gameId: string) => {
    try {
      const fetchedGameData = await GameServiceInstance.fetchGameById(gameId);
      if (fetchedGameData) {
        this.momentGameData = fetchedGameData;
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchGameData()", err.message);
    }
  }

  private fetchMomentMomento = async (_id: string) => {
    const response = await getMomentMomentoListRequest(_id);
    return response;
  }

  private fetchAllMomento = async () => {
    this.momentoLinksLoading = true;
    try {
      let previewLinks: string[] = [];
      let fullSizeLinks: string[] = [];
      for (let ind = 0, len = this.selectedMoment.moments.length; ind < len; ind += 1) {
        const m = this.selectedMoment.moments[ind];
        if (m.camera === this.selectedMomentCameraName) {
          // eslint-disable-next-line no-await-in-loop
          const momentoListResponse = await this.fetchMomentMomento(m._id);
          previewLinks = [...previewLinks, ...momentoListResponse.momentOLinks];
          const untypedResponse : any = momentoListResponse;
          if ("fullSizeLinks" in untypedResponse) {
            fullSizeLinks = [...fullSizeLinks, ...untypedResponse.fullSizeLinks];
          }
        }
      }

      this.momentoPreviewLinks = previewLinks.map((link) => {
        // Find the width and height parameters in the links and divide by 10
        const url = new URL(link);
        // const width = parseInt(url.searchParams.get("width"), 10);
        // const height = parseInt(url.searchParams.get("height"), 10);
        url.searchParams.set("width", "600"); // `${width / 10}`
        url.searchParams.set("height", "600"); // `${height / 10}`
        return url.href;
      });

      this.momentoLinks = previewLinks;
      this.momentoFullSizeLinks = fullSizeLinks;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchAllMomento()", err.message);
    } finally {
      this.momentoLinksLoading = false;
    }
  }

  private fetchMomentMomentoForSeatPreview = async (seat: PreviewSeat, _id: string) => {
    const response = await getMomentSeatPreviewAllRequest(
      seat,
      _id,
    );

    return {
      mLinks: response.momentOLinks,
      prSeat: response.prSeat,
    };
  }

  public getSeatPreview = async (seat?: PreviewSeat): Promise<void> => {
    this.seatPreviewLoading = true;
    try {
      let links: string[] = [];
      let previewSeat = seat;
      for (let ind = 0, len = this.selectedMoment.moments.length; ind < len; ind += 1) {
        const m = this.selectedMoment.moments[ind];
        if (m.camera === this.selectedMomentCameraName) {
          const {
            mLinks,
            prSeat,
          // eslint-disable-next-line no-await-in-loop
          } = await this.fetchMomentMomentoForSeatPreview(previewSeat, m._id);

          if (!previewSeat) {
            previewSeat = prSeat;
          }

          links = [...links, ...mLinks];
        }
      }

      this.previewSeats = links;
      const { row: r, seat: s, sectionName } = previewSeat;
      this.previewTitle = `Preview (section ${sectionName} row ${r} seat ${s})`;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("getSeatPreview()", err.message);
    } finally {
      this.seatPreviewLoading = false;
    }
  }

  public clearSeatPreview = () => {
    this.previewSeats = [];
  }

  public updateMoment = async (
    request: UpdateMomentRequest,
    _id: string,
    tag?: MomentTag,
  ): Promise<boolean> => {
    try {
      if (await updateGeneralMomentRequest(request, _id)) {
        const updatedMoment = await getGeneralMomentRequest(_id);
        const index = this.momentList.findIndex((m) => m._id === _id);

        const updatedMomentWithTag: GeneralMomentClientWithTag = {
          ...updatedMoment,
          tag,
        };

        if (index !== -1) {
          this.momentList.splice(index, 1, updatedMomentWithTag);
          if (this.selectedMoment && this.selectedMoment._id === _id) {
            this.selectedMoment = updatedMomentWithTag;
          }
          return true;
        }
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateMoment()", err.message);
      return false;
    }
  }

  @action public analyzeMoments = async (request: AnalyzeMomentsRequest): Promise<boolean> => {
    try {
      const analyzeResponse = await analyzeMomentsRequest(request);
      if (analyzeResponse) {
        NotificationServiceInstance.showInfoNotification(`${analyzeResponse.totalNumberOfMoments} Moment${analyzeResponse.totalNumberOfMoments === 1 ? "" : "s"} with ${analyzeResponse.totalNumberOfImages} total images queued for analysis`);
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("analyzeMoments()", err.message);
      return false;
    }
  }

  @action public publishMoment = (momentId: string, sportName?: string) => {
    const targetGMoment = this.momentList.find((m) => m._id === momentId);
    if (!targetGMoment) return;

    let canPublish = true;
    const isOtherSport = sportName && sportName.startsWith("Other");
    if (!targetGMoment.moments[0].text) {
      message.warn("Please specify the description to publish the moment");
      canPublish = false;
    }
    if (!isValidNumber(targetGMoment.moments[0].team1Score) && !isOtherSport) {
      message.warn("Please specify the team 1 score to publish the moment");
      canPublish = false;
    }
    if (!isValidNumber(targetGMoment.moments[0].team2Score) && !isOtherSport) {
      message.warn("Please specify the team 2 score to publish the moment");
      canPublish = false;
    }

    if (canPublish) {
      this.updateMoment({ status: MomentStatus.PUBLISHED }, momentId, "published");
    }
  }

  @action public archiveMoment = (momentId: string) => {
    const targetMoment = this.momentList.find((m) => m._id === momentId);
    if (!targetMoment) return;

    this.updateMoment({ status: MomentStatus.ARCHIVED }, momentId, "archived");
  }

  @action public setIsMOTGMoment = (momentId: string, checked: boolean) => {
    const targetMoment = this.momentList.find((m) => m._id === momentId);
    if (!targetMoment) return;

    this.updateMoment({ isMOTG: checked }, momentId);
  }

  @action public setHideScoreboardMoment = (momentId: string, checked: boolean) => {
    const targetMoment = this.momentList.find((m) => m._id === momentId);
    if (!targetMoment) return;

    this.updateMoment({ hideScoreboard: checked }, momentId);
  }

  @action public onUseInAnalyze = (momentId: string, checked: boolean) => {
    const index = this.momentList.findIndex((m) => m._id === momentId);
    if (index !== -1) {
      const moment = this.momentList[index];
      const updatedMoment = {
        ...moment,
        useInAnalyze: checked,
      };

      this.momentList.splice(index, 1, updatedMoment);

      if (this.selectedMoment && this.selectedMoment._id === momentId) {
        this.selectedMoment = updatedMoment;
      }
    }
  }

  /**
   * Socket actions
   */
  @action socketAddDraftGeneralMoment = (gameId: string, moment: GeneralMomentClient): boolean => {
    if (!this.momentGameData || this.momentGameData._id !== gameId) {
      console.log("[new draft moment][moments service] moment wasn't added to the list since it's not from the selected game");
      return false;
    }

    this.momentList.unshift(moment);
    console.log("[new draft moment][moments service] moment was added to the list");
    return true;
  }

  @action socketUpdateGeneralMoment = (
    generalMomentId: string,
    moment: OperatorMoment,
  ): void => {
    const targetGMomentIndex = this.momentList.findIndex((m) => m._id === generalMomentId);
    if (targetGMomentIndex === -1) return;

    const targetGMoment = this.momentList[targetGMomentIndex];
    targetGMoment.moments.push(moment);

    NotificationServiceInstance.showUpdatedMomentNotification(
      targetGMoment._id,
      moment._id,
      moment.camera,
      (gMomentId, momentId) => {
        const gMoment = this.momentList.find((m) => m._id === gMomentId);
        if (!gMoment) return;

        const cameraIndex = gMoment.moments.findIndex((m) => m._id === momentId);

        if (cameraIndex !== -1) {
          const cameraName = gMoment.moments[cameraIndex].camera;
          this.selectMoment(gMoment, cameraName);
        }
      },
    );
  }
}

export const MomentServiceInstance = new MomentService();

export const MomentServiceContext = createContext(MomentServiceInstance);
