import { makeAutoObservable, observable } from "mobx";
import { createContext } from "react";
import axios from "axios";

import * as Request from "../axios/routes/teams";
import * as SportRequest from "../axios/routes/sport";
import { AppServiceInstance } from "./app";
import { NameInput, TeamAdsWithId, TeamPopulated, UpdateTeamAdsRequest } from "../shared/interfaces";
import { DEFAULT_SELECT_VALUE } from "../utils/constants";
import { TableTag } from "../interfaces/base";
import { NotificationServiceInstance } from "./notification";
import { GameServiceInstance } from "./game";
import { WebAppSubdomain } from "../utils/domainAnalysis";

export interface TeamPopulatedWithTag extends TeamPopulated, TableTag {}

interface TeamAdsUpdateResponse {
  _id: string;
  success: boolean;
  uploadURL?: string;
}

type UploadProgressCallback = (percentage: number) => any;

class TeamsService {
  @observable isInitialized = false;

  @observable leagueList: NameInput[] = [];

  @observable teams: TeamPopulatedWithTag[] = [];

  @observable selectedSport: string = null;

  @observable selectedLeague: string = null;

  @observable teamFromSubdomain: TeamPopulatedWithTag = null;

  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  private init = async () => {
    if (!AppServiceInstance.token) {
      console.log("> Token is not set yet, waiting for 300ms");
      setTimeout(() => {
        this.init();
      }, 300);
      return;
    }

    await this.fetchTeamList();

    const webAppSubdomain = WebAppSubdomain();
    console.log(`TeamsService.init() - webAppSubdomain=${(webAppSubdomain === null) ? "[null]" : webAppSubdomain}`);
    if (webAppSubdomain) {
      const subdomainTeamName = webAppSubdomain.toLowerCase().replace("-", " ");
      const team = this.teams.find(
        (c) => c.name.toLowerCase().endsWith(subdomainTeamName),
      );

      if (team) {
        this.teamFromSubdomain = team;

        GameServiceInstance.onSportChange(team.sport._id);
        GameServiceInstance.onLeagueChange(team.league[0]._id);
        GameServiceInstance.onTeamChange(team._id);

        this.onSportChange(team.sport._id);
        this.onLeagueChange(team.league[0]._id);
      }
    }
    this.isInitialized = true;
  }

  private fetchLeagueList = async (sportId: string) => {
    try {
      this.leagueList = await SportRequest.getLeagueListRequest({ sport: sportId });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchSportList()", err.message);
    }
  }

  private fetchTeamList = async () => {
    try {
      this.teams = await Request.getTeamListRequest({
        league: this.selectedLeague, sport: this.selectedSport,
      });
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchTeamList()", err.message);
    }
  }

  public onSportChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedSport = null;
      this.leagueList = [];
    } else {
      this.selectedSport = _id;
      this.fetchLeagueList(_id);
    }
    this.selectedLeague = null;
    this.fetchTeamList();
  }

  public onLeagueChange = (_id: string) => {
    if (_id === DEFAULT_SELECT_VALUE) {
      this.selectedLeague = null;
    } else {
      this.selectedLeague = _id;
    }
    this.fetchTeamList();
  }

  public deleteTeam = async (_id: string) => {
    try {
      if (await Request.deleteTeamRequest(_id)) {
        this.fetchTeamList();
      }
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("deleteTeam()", err.message);
    }
  }

  public getTeamById = async (teamId: string): Promise<TeamPopulated|null> => {
    try {
      return await Request.getTeamByIdRequest(teamId);
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("createTeam()", err.message);
      return null;
    }
  }

  public createTeam = async (request: FormData): Promise<boolean> => {
    try {
      const { _id } = await Request.addTeamRequest(request);
      if (!_id) return false;

      const newTeam = await Request.getTeamByIdRequest(_id);
      this.teams.unshift({
        ...newTeam,
        tag: "new",
      });

      return true;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("createTeam()", err.message);
      return false;
    }
  }

  public updateTeam = async (request: FormData, _id: string): Promise<boolean> => {
    try {
      if (await Request.updateTeamRequest(request, _id)) {
        const updatedTeam = await Request.getTeamByIdRequest(_id);
        const index = this.teams.findIndex((t) => t._id === _id);
        if (index !== -1) {
          this.teams.splice(index, 1, {
            ...updatedTeam,
            tag: "updated",
          });
        }
        return true;
      }
      return false;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateTeam()", err.message);
      return false;
    }
  }

  public createTeamAds = async (request: UpdateTeamAdsRequest): Promise<string> => {
    try {
      const { _id } = await Request.addTeamAdsRequest(request);
      return _id;
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("createTeamAds()", err.message);
      return "";
    }
  }

  public updateTeamAds = async (
    request: FormData,
    _id: string,
    imageFile: File,
    mimeType: string,
    uploadProgressCallback?: UploadProgressCallback,
  ): Promise<TeamAdsWithId> => {
    try {
      const result = await Request.updateTeamAdsRequest(
        request, _id,
      ) as unknown as TeamAdsUpdateResponse;

      if (result?.success) {
        if (result.uploadURL) {
          console.log("TeamsService.updateTeamAds() - Uploading to: ", result.uploadURL);

          const uploadResult = await axios.request({
            url: result.uploadURL,
            method: "PUT",
            data: imageFile,
            headers: { "Content-Type": mimeType },
            onUploadProgress: (p) => {
              const progressPercent = parseFloat(((100.0 * p.loaded) / p.total).toFixed(2));
              if (uploadProgressCallback) {
                uploadProgressCallback(progressPercent);
              }
            },
          });

          console.log("TeamsService.updateCamera() - image upload result: ", uploadResult);
        } else {
          if (uploadProgressCallback) {
            uploadProgressCallback(100);
          }
        }

        const updatedTeamAds = await Request.getTeamAddsByIdRequest(_id);

        return updatedTeamAds;
      }

      if (uploadProgressCallback) {
        uploadProgressCallback(100);
      }

      return {};
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("updateTeam Advertisments()", err.message);

      if (uploadProgressCallback) {
        uploadProgressCallback(100);
      }

      return {};
    }
  }
}

export const TeamsServiceInstance = new TeamsService();

export const TeamsServiceContext = createContext(TeamsServiceInstance);
