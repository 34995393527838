import { Button } from "antd";
import styled from "styled-components";

export const Z_INDEX = {
  HIGHEST: 9999,
  HIGH: 999,
  MIDDLE: 99,
};

export const COLORS = {
  BLUE_MAIN: "#0A7CB2",
  BLUE_DARK: "#292e4a",
  BLUE_LIGHT: "#66aed1",
  WHITE: "#FFFFFF",
  BLACK_MAIN: "#000000",
  GREY: "#A7A4A4",
  GREY_MAIN: "#9ba6b2",
  GREY_DARK: "#ada8a8",
  GRADIENT: "rgba(255, 255, 255, 0.252332)",
  GREEN: "#4CE6C4",
  GREEN_DARK: "#02653B",
  RED: "#FF0000",
};

export const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: ${Z_INDEX.HIGHEST};
  background-color: ${COLORS.BLACK_MAIN};
`;

export enum FontFamilyTypeEnum {
  GILROY="Gilroy",
  MONTSERRAT="Montserrat",
  OSWALD="Oswald",
  GILL_SANS="GillSansC",
  SFPRO="SFPRO",
  Seahawks="Seahawks",
  Copperplate="Copperplate",
  Mariners="Mariners"
}

type BasicButtonProps = {
  fontFamily?: FontFamilyTypeEnum;
  backgroundColor?: string;
  borderColor?: string;
  flexGrow?: number;
  margin?: string;
  width?: string;
  disabled?: boolean;
  fontWeight?: string;
  fontSize?: string;
  color?: string;
}

export const BasicButton = styled.button<BasicButtonProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.SFPRO};
  padding: 5px 17.5px 5px 17.5px;
  border-radius: 5px;
  border: 1px solid;
  width: ${({ width }) => width || "auto"};
  background-color: ${({ backgroundColor, disabled }) => (disabled ? COLORS.GREY : backgroundColor || COLORS.WHITE)};
  border-color: ${({ borderColor, disabled, backgroundColor }) => (disabled ? COLORS.GREY : borderColor || backgroundColor || COLORS.BLUE_LIGHT)};
  color: ${({ disabled, color }) => (disabled ? COLORS.WHITE : color || COLORS.BLACK_MAIN)};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: 20px;
  height: 30px;
  cursor:  ${({ disabled }) => {
    return disabled ? "not-allowed" : "pointer";
  }};
  margin: ${({ margin }) => margin || "0px"};
`;

export const AntdBasicButton = styled(Button)<BasicButtonProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.SFPRO};
  padding: 5px 17.5px 5px 17.5px;
  border-radius: 5px;
  border: 1px solid;
  width: ${({ width }) => width || "auto"};
  background-color: ${({ backgroundColor, disabled }) => (disabled ? COLORS.GREY : backgroundColor || COLORS.WHITE)};
  border-color: ${({ borderColor, disabled, backgroundColor }) => (disabled ? COLORS.BLACK_MAIN : borderColor || backgroundColor || COLORS.BLUE_LIGHT)};
  color: ${({ disabled, color }) => (disabled ? COLORS.WHITE : color || COLORS.BLACK_MAIN)};
  flex-grow: ${({ flexGrow }) => flexGrow || 0};
  font-size: ${({ fontSize }) => fontSize || "14px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: 20px;
  height: 30px;
  cursor:  ${({ disabled }) => {
    return disabled ? "not-allowed" : "pointer";
  }};
  margin: ${({ margin }) => margin || "0px"};
`;

interface BasicTextProps {
  fontFamily?: FontFamilyTypeEnum;
  color?: string;
  fontSize?: string;
  lineHeight?: string;
  fontWeight?: string;
  letterSpacing?: string;
  textAlign?: "left"|"center"|"right";
  margin?: string;
  underline?: boolean;
  blockWidth?: string;
}

export const BasicText = styled.div<BasicTextProps>`
  font-family: ${({ fontFamily }) => fontFamily || FontFamilyTypeEnum.SFPRO};
  font-size: ${({ fontSize }) => fontSize || "12px"};
  font-style: normal;
  font-weight: ${({ fontWeight }) => fontWeight || "400"};
  line-height: ${({ lineHeight, fontSize }) => lineHeight || fontSize || "12px"};
  letter-spacing: ${({ letterSpacing }) => letterSpacing || "0px"};
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
  text-align: ${({ textAlign }) => textAlign || "center"};
  margin: ${({ margin }) => margin || 0};
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};
  width: ${({ blockWidth }) => blockWidth || "auto"};
`;

interface BasicContentWrapperProps {
  background?: string;
  color?: string;
  padding?: string;
  justifyContent?: "space-between"|"flex-start";
  scrollable?: boolean;
  width?: string;
  height?: string;
}

export const BasicContentWrapper = styled.div<BasicContentWrapperProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${({ justifyContent }) => justifyContent || "flex-start"};
  align-items: center;
  padding: ${({ padding }) => padding || "10px 16px"}; 
  color: ${({ color }) => color || COLORS.BLACK_MAIN};
  background-color: ${({ background }) => background || "transparent"};
  overflow-y: auto;
  font-family: ${FontFamilyTypeEnum.SFPRO};
  position: relative;
  width: ${({ width }) => width || "100%"};
  height: ${({ height }) => height || "auto"};
`;

interface CommonImgWrapperProps {
  width?: string;
  height?: string;
  position?: "absolute"|"fixed";
  margin?: string;
}

export const BasicImgWrapper = styled.img<CommonImgWrapperProps>`
  cursor: pointer;
  width: ${({ width }) => width || "auto"};
  height: ${({ height }) => height || "auto"};
  position: ${({ position }) => position || "relative"};
  margin: ${({ margin }) => margin || 0};
`;

interface ContentHeaderProps {
  height?: string;
  withoutPadding?: boolean;
}

export const ContentHeader = styled.div<ContentHeaderProps>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: ${({ height }) => height || "60px"};
  padding: ${({ withoutPadding }) => (withoutPadding ? "0" : "0 20px")};
`;

export const ContentHeaderCenterItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
`;

interface ContentHeaderItemProps {
  width?: string;
}

const ContentHeaderItem = styled.div<ContentHeaderItemProps>`
  display: flex;
  align-items: center;
  width: ${({ width }) => width || "15%"};
`;

export const ContentHeaderLeftItem = styled(ContentHeaderItem)`
  justify-content: flex-start;
`;

export const ContentHeaderRightItem = styled(ContentHeaderItem)`
  justify-content: flex-end;
`;

export const ScrollableBlock = styled.div`
  width: 100%;
  height: 100%;
  overflow-y: auto;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const RefreshButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute; // or relative, depending on your layout
  top: 50px; // adjust this value based on your exact header height
  left: 0;
  padding-left: 20px; // align with the MOMENTO logo
  padding-top: 20px; // space above the button
`;

export const TeamInfoWrapper = styled.div`
  display: flex;
  align-items: center;
`;
