import { notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

import { OperatorGamePopulated } from "../shared/interfaces";
import { AntdBasicButton, COLORS } from "../styled/basic";
import { NavigationServiceInstance } from "./navigation";

type UpdatedMomentNotificationCallback = (gMomentId: string, momentId: string) => void;

class NotificationService {
  public updatedMomentNotificationKeys: string[] = []

  public closeUpdatedMomentNotification = () => {
    this.updatedMomentNotificationKeys.forEach((key) => {
      notification.close(key);
    });
    this.updatedMomentNotificationKeys = [];
  }

  /**
   * Is used when new General Moment (moment that combines moments from all cameras)
   * is created. User can go to that moment
   * @param game game data with updated moments counter
   */
   public showDraftMomentNotification = (game: OperatorGamePopulated) => {
     const notificationKey = `${Date.now()}-${game._id}`;
     const button = (
       <AntdBasicButton
         onClick={() => {
           NavigationServiceInstance.goToGameMoments(game._id);
           notification.close(notificationKey);
         }}
       >
         View
       </AntdBasicButton>
     );

     notification.open({
       btn: button,
       message: `"${game.team1.name}" Vs. "${game.team2.name}" just got a new Moment. Current draft moments ${game.draftMoments}`,
       key: notificationKey,
       duration: 100,
       placement: "bottomRight",
     });
   }

  /**
   * Is used when General Moment is updated (moment camera a different camera appeared)
   * User can go to the moment if they are on the target game's moments
   */
  public showUpdatedMomentNotification = (
    gMomentId: string,
    momentId: string,
    cameraName: string,
    callback: UpdatedMomentNotificationCallback,
  ) => {
    const notificationKey = `${Date.now()}-${momentId}`;
    const button = (
      <AntdBasicButton
        onClick={() => {
          notification.close(notificationKey);
          callback(gMomentId, momentId);
        }}
      >
        View
      </AntdBasicButton>
    );

    this.updatedMomentNotificationKeys.push(notificationKey);

    notification.open({
      btn: button,
      message: `One of the moments was updated. New images from "${cameraName}" camera`,
      key: notificationKey,
      duration: 100,
      placement: "bottomRight",
    });
  }

  public showErrorNotification = (message: string, duration?: number): void => {
    notification.open({
      message: "Error",
      description: message,
      duration: duration || 4.5,
      icon: <InfoCircleOutlined style={{ color: COLORS.RED }} />,
    });
  };

  public showUnexpectedErrorNotification = (
    source: string, message: string, fullError: unknown = null,
  ): void => {
    if (fullError) {
      console.error(`>> ${source} full error: `, fullError);
    }

    const errorContent = `Error occured while calling '${source}'. Message: ${message}.`;

    console.error(`>> ${errorContent}`);

    notification.open({
      message: "Error",
      description: message,
      duration: 4.5,
      icon: <InfoCircleOutlined style={{ color: COLORS.RED }} />,
    });
  };

  public showInfoNotification = (message: string, duration?: number): void => {
    notification.open({
      message,
      duration: duration || 3.5,
      icon: <InfoCircleOutlined style={{ color: COLORS.BLUE_MAIN }} />,
    });
  };
}

export const NotificationServiceInstance = new NotificationService();
