import { createContext } from "react";
import { makeAutoObservable, observable } from "mobx";
import * as Cookies from "js-cookie";
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
} from "amazon-cognito-identity-js";
import { SessionData } from "../interfaces/session";
import { UsersServiceInstance } from "./users";
import { CognitoTokenRefreshResponse, CognitoTokenResponse } from "../shared/interfaces";
import { getEmptyUserRights, parseUserRights, UserRightsType } from "../utils/groups";

class SessionService {
  @observable EmailAddress = null;

  @observable UserGroups: string[] = [];

  @observable UserRights: UserRightsType | null = null;

  constructor() {
    makeAutoObservable(this);

    this.init();
  }

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  private init = async () => {}

  public static checkTokenExpiration = async (sessionData: SessionData) : Promise<SessionData> => {
    return new Promise((resolve, reject) => {
      const cognitoRefreshToken = new CognitoRefreshToken({
        RefreshToken: sessionData.RefreshToken,
      });
      const cognitoAccessToken = new CognitoAccessToken({ AccessToken: sessionData.AccessToken });
      const cognitoIdToken = new CognitoIdToken({ IdToken: sessionData.IdToken });
      const cachedSession = new CognitoUserSession({
        AccessToken: cognitoAccessToken,
        IdToken: cognitoIdToken,
        RefreshToken: cognitoRefreshToken,
      });

      const accessTokenExpirationDate = new Date(cognitoAccessToken.getExpiration() * 1000);
      const idTokenExpirationDate = new Date(cognitoIdToken.getExpiration() * 1000);
      const now = new Date();
      console.log(`SessionService.checkTokenExpiration() - AccessToken ${(accessTokenExpirationDate > now) ? "VALID until " : "EXPIRED at "}`, accessTokenExpirationDate);
      console.log(`SessionService.checkTokenExpiration() - IdToken ${(idTokenExpirationDate > now) ? "VALID until " : "EXPIRED at "}`, idTokenExpirationDate);

      if ((cachedSession.isValid() === true)
       && (idTokenExpirationDate > now)
       && (accessTokenExpirationDate > now)) {
        // cached session tokens are valid, and not expired
        resolve(sessionData);
      } else {
        // cached session tokens are either invalid or expired
        // TODO: only refresh the authentication tokens if the user has explicitly said
        //       "remember me"
        UsersServiceInstance.refreshCognitoTokens(sessionData).then(
          (response: CognitoTokenRefreshResponse) => {
            console.log("UsersServiceInstance.refreshCognitoTokens() returned ", response);
            if (response == null) {
              reject({ Error: "UsersServiceInstance.refreshCognitoTokens() returned null" });
            } else {
              resolve({
                AccessToken: response.AccessToken,
                IdToken: response.IdToken,
                RefreshToken: response.RefreshToken,
                EmailAddress: sessionData.EmailAddress,
              });
            }
          },
        ).catch(
          (error) => {
            console.log("Error refreshing tokens:", error);
            reject(error);
          },
        );
      }
    });
  };

  public setSession = (data: SessionData) => {
    console.log("setSession", { ...data });
    this.EmailAddress = data.EmailAddress;

    // TODO:  Store session in Storage instead of explicit cookies
    const cookieNamePair = [
      ["SessionAccessToken", data.AccessToken],
      ["SessionIdToken", data.IdToken],
      ["SessionRefreshToken", data.RefreshToken],
      ["SessionEmailAddress", data.EmailAddress],
    ];
    cookieNamePair.forEach((pair) => {
      if (pair[1]) {
        Cookies.set(pair[0], pair[1], { expires: 14 });
      } else {
        Cookies.remove(pair[0]);
      }
    });
  };

  private getUserRights = async () => {
    // test groups, it is necessary to load them from cognito
    const groups = [
      "administrators",
      "teamAdmin_team2",
      "stadiumAdmin_Spartan Stadium",
    ];

    this.UserGroups = groups;
    this.UserRights = parseUserRights(groups);
  }

  public getCookie = async (name: string): Promise<string> => {
    const value = Cookies.get(name);

    return value;
  }

  public setCookie = async (name: string, value: string) => {
    Cookies.set(name, value);
  }

  public getSession = async (): Promise<SessionData> => {
    console.log("try get session");
    // TODO:  Store session in Storage instead of explicit cookies
    const sessionData : SessionData = {
      IdToken: Cookies.get("SessionIdToken"),
      AccessToken: Cookies.get("SessionAccessToken"),
      RefreshToken: Cookies.get("SessionRefreshToken"),
      EmailAddress: Cookies.get("SessionEmailAddress"),
      // eslint-disable-next-line no-empty
    };
    console.log("sessionData=", { ...sessionData });
    this.EmailAddress = sessionData.EmailAddress;
    let updatedSessionData = null;
    if (sessionData.AccessToken) {
      // try {
      updatedSessionData = await SessionService.checkTokenExpiration(sessionData);
      if (sessionData.AccessToken !== updatedSessionData.AccessToken) {
        this.setSession(updatedSessionData);
      }
      // } catch (err) {
      //  updatedSessionData = null;
      // }

      await this.getUserRights();
      console.log(this.UserRights);
    }

    return updatedSessionData;
  }

  public clearSession = async () => {
    console.log("clearSession");
    this.EmailAddress = null;

    // TODO:  Store session in Storage instead of explicit cookies
    const cookieNamePair = [
      ["SessionAccessToken", ""],
      ["SessionIdToken", ""],
      ["SessionRefreshToken", ""],
      ["SessionEmailAddress", ""],
    ];

    cookieNamePair.forEach((pair) => {
      Cookies.remove(pair[0]);
    });
  }
}

export const SessionServiceInstance = new SessionService();

export const SessionContext = createContext(SessionServiceInstance);
