import { stringify } from "query-string";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import {
  TeamPopulated, DocumentInput, GetTeamListRequest,
  TeamAdsWithId, GetTeamAdsByTeamRequest, UpdateTeamAdsRequest,
} from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getTeamListRequest = async (request: GetTeamListRequest): Promise<TeamPopulated[]> => {
  const stringified = request.league || request.sport ? stringify(request, {
    skipNull: true,
  }) : null;

  try {
    const response = await instance.get(`${PATH.API}/${PATH.TEAM}?${stringified}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getTeamByIdRequest = async (teamId: string): Promise<TeamPopulated> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.TEAM}/${teamId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getTeamAddsByIdRequest = async (_id: string): Promise<TeamAdsWithId> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.TEAM_ADS}/${_id}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getTeamAddsByTeamIdRequest = async (
  request: GetTeamAdsByTeamRequest,
): Promise<TeamAdsWithId> => {
  try {
    const stringified = stringify(request, {
      skipNull: true,
    });
    const response = await instance.get(`${PATH.API}/${PATH.TEAM_ADS_FOR_TEAM}?${stringified}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addTeamRequest = async (request: FormData): Promise<DocumentInput> => {
  try {
    const response = await instance.post<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.TEAM}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateTeamRequest = async (
  request: FormData, teamId: string,
): Promise<DocumentInput> => {
  try {
    const response = await instance.put<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.TEAM}/${teamId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addTeamAdsRequest = async (request: UpdateTeamAdsRequest): Promise<DocumentInput> => {
  try {
    const response = await instance.post<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.TEAM_ADS}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateTeamAdsRequest = async (
  request: FormData, teamAdsId: string,
): Promise<DocumentInput> => {
  try {
    const response = await instance.put<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.TEAM_ADS}/${teamAdsId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const deleteTeamRequest = async (teamId: string): Promise<boolean> => {
  try {
    await instance.delete<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.TEAM}/${teamId}`);

    return true;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
