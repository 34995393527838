import { observer } from "mobx-react";
import React, { useState, useEffect, useContext } from "react";
import { Spin } from "antd";
import { useLocation, useHistory, Route } from "react-router-dom";
import { parse } from "query-string";
import { CognitoIdToken, CognitoUser } from "amazon-cognito-identity-js";
import { SessionContext } from "../../services/session";
import { PARAMS, Routes } from "../../utils/routes";
import { COLORS } from "../../styled/basic";
import { UsersServiceInstance } from "../../services/users";
import { WebAppPort, WebAppProtocol, WebAppSubdomain, TruncatedHostname } from "../../utils/domainAnalysis";

// TODO: Pull this information from environmet variables
const COGNITO_DOMAIN = "dev-momento-operator";
const COGNITO_REGION = "us-west-2";
// const COGNITO_HOSTED_UI_CLIENT_ID = "408unpd7ggh9mit023bb5cbotr"; // dev-operatorApp-serverClient
const COGNITO_HOSTED_UI_CLIENT_ID = "2c2l3f2728o74j32v1ba9b7p"; // dev-operatorApp-client

let cognitoHostedUIRedirectUri = `${window.location.origin}/`;
let cognitoHostedUILoginURL = `https://${COGNITO_DOMAIN}.auth.${COGNITO_REGION}.amazoncognito.com/login?client_id=${COGNITO_HOSTED_UI_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${cognitoHostedUIRedirectUri}`;
let cognitoHostedUILogoutURL = `https://${COGNITO_DOMAIN}.auth.${COGNITO_REGION}.amazoncognito.com/logout?client_id=${COGNITO_HOSTED_UI_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${cognitoHostedUIRedirectUri}`;

const DEFAULT_PAGE = Routes.GAMES;

interface LoginComponentProps {
  cognitoUser? : CognitoUser;
  userAttributes?: any;
}

let subdomainAnalysisComplete = false;
const subdomainAnalysis = (): void => {
  if (subdomainAnalysisComplete === false) {
    // determine if the site was accessed through a subdomain, in which case we need to
    // reset the cognitoHostedUIRedirectUri to the base URL of the subdomain
    const INITIAL_PROTOCOL = WebAppProtocol();
    const TRUNCATED_HOSTNAME = TruncatedHostname();
    const APP_SUBDOMAIN = WebAppSubdomain();
    const INITIAL_PORT = WebAppPort();

    console.log(`login.tsx / subdomainAnalysis() - subdomain=${APP_SUBDOMAIN}`);
    if (APP_SUBDOMAIN !== "") {
      cognitoHostedUIRedirectUri = `${INITIAL_PROTOCOL}//${TRUNCATED_HOSTNAME}${(INITIAL_PORT === "") ? "" : `:${INITIAL_PORT}`}/`;
      cognitoHostedUILoginURL = `https://${COGNITO_DOMAIN}.auth.${COGNITO_REGION}.amazoncognito.com/login?client_id=${COGNITO_HOSTED_UI_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${encodeURIComponent(cognitoHostedUIRedirectUri)}&state=${encodeURIComponent(window.location.href)}`;
      cognitoHostedUILogoutURL = `https://${COGNITO_DOMAIN}.auth.${COGNITO_REGION}.amazoncognito.com/logout?client_id=${COGNITO_HOSTED_UI_CLIENT_ID}&response_type=code&scope=email+openid+phone+profile&redirect_uri=${encodeURIComponent(cognitoHostedUIRedirectUri)}&state=${encodeURIComponent(window.location.href)}`;
    }

    subdomainAnalysisComplete = true;
  }
};

export const CognitoHostedUILoginURL = (): string => {
  subdomainAnalysis();
  return cognitoHostedUILoginURL;
};

export const CognitoHostedUILogoutURL = (): string => {
  subdomainAnalysis();
  return cognitoHostedUILogoutURL;
};

export const CognitoHostedUIRedirectUri = (): string => {
  subdomainAnalysis();
  return cognitoHostedUIRedirectUri;
};

const LoginPage = observer((): JSX.Element => {
  const [isAuthorized, setAuthorized] = useState(true);
  const [authorizedSites, setAuthorizedSites] = useState([] as any[]);

  const { search, pathname } = useLocation();
  const parsedQueryParams = parse(search);

  const sessionContext = useContext(SessionContext);

  const exchangeCodeForTokens = async (): Promise<any> => {
    const code = parsedQueryParams[PARAMS.CODE] as string;

    const result = await UsersServiceInstance.getCognitoTokens(
      code, CognitoHostedUIRedirectUri(),
    );

    return result;
  };

  const getRedirectURL = (subdomain): string => {
    const INITIAL_PROTOCOL = WebAppProtocol();
    const TRUNCATED_HOSTNAME = TruncatedHostname();
    const INITIAL_PORT = WebAppPort();

    const code = parsedQueryParams[PARAMS.CODE] as string;

    const redirectUrl = `${INITIAL_PROTOCOL}//${(subdomain === "") ? "" : `${subdomain}.`}${TRUNCATED_HOSTNAME}${(INITIAL_PORT === "") ? "" : `:${INITIAL_PORT}`}/`; // ?code=${code}
    console.log(`LoginPage.getRedirectURL(subdomain=${subdomain}) - redirectUrl=${redirectUrl}`);
    return redirectUrl;
  };

  const setSession = async (): Promise<void> => {
    console.log("LoginPage.setSession() - start", search, parsedQueryParams);
    // aws cognitio redirected back with provided code
    const code = parsedQueryParams[PARAMS.CODE] as string;
    const state = parsedQueryParams[PARAMS.STATE] as string;
    if (code) {
      console.log(`LoginPage.setSession() - Code=${code}, state=${state}`);

      // if state is set, it is because the site was accessed throught a subdomain;
      //  immediately redirect
      if (state) {
        const urlParamPrefix = state.includes("?") ? "&" : "?";
        // append the code as a param, so that the subdomain version of this page
        // can record the code in a session associated with the subdomain
        const redirectUrl = `${state}${urlParamPrefix}${PARAMS.CODE}=${code}`;
        window.location.href = redirectUrl;
        return;
      }

      try {
        const userTokens = await exchangeCodeForTokens();
        console.log("LoginPage.setSession() - exchange for tokens", { ...userTokens });

        const cognitoIdToken = new CognitoIdToken({ IdToken: userTokens.id_token });
        const idTokenPayload = cognitoIdToken.decodePayload();
        console.log("LoginPage.setSession() - accessTokenPayload=", { ...idTokenPayload });

        // the user is authenticated into the Momento system;
        //   now we need to ensure that the user is authorized for this stadium
        const webAppSubdomain = WebAppSubdomain();

        console.log(`LoginPage.setSession() - subdomain=${webAppSubdomain}`);
        const cognitoGroups = idTokenPayload["cognito:groups"] || [];
        const userIsAuthorizedForThisSubdomain = (webAppSubdomain === "")
          // the user is attempting to access the main operator site
          //   to get this access, the user MUST be a member of the cognito group
          //   "superAdministrators"
          ? (cognitoGroups.includes("superAdministrators"))

          // the user is attempting to access a team operator site
          //   to get this access, the user MUST be a member of the cognito group
          //   "teamAdministrators.{webAppSubdomain}" -- for example for a team named "Cubs",
          //   the user should be a member of the cognito group "teamAdministrators.cubs"
          : (cognitoGroups.includes("superAdministrators"))
            || (cognitoGroups.includes(`teamAdministrators.${webAppSubdomain}`));

        if (userIsAuthorizedForThisSubdomain === false) {
          // the user is not authorized for the main operator site, but let's see if they're
          // authorized for specific team operator sites
          // setAuthorizedSites(cognitoGroups
          //  .filter((item) => /^teamAdministrators\.(\w+)$/.test(item))
          //  .map((item) => { return { title: item.split(".")[1] }; }));

          const filteredMainSite = cognitoGroups
            .filter((item) => /^superAdministrators$/.test(item));
          const filteredTeamSites = cognitoGroups
            .filter((item) => /^teamAdministrators\.(\w+)$/.test(item));
          const filteredSites = filteredMainSite.concat(filteredTeamSites);
          const mappedSites = filteredSites
            .map((item) => { return { title: (item === "superAdministrators") ? "" : item.split(".")[1] }; });
          setAuthorizedSites(mappedSites);

          setAuthorized(false);
          return;
        }

        // store access token, for all pertinent subsequent API calls to establish identity
        sessionContext.setSession({
          AccessToken: userTokens.access_token,
          IdToken: userTokens.id_token,
          RefreshToken: userTokens.refresh_token,
          EmailAddress: idTokenPayload.email,
        });

        const sessionData = await sessionContext.getSession();
        console.log("LoginPage.setSession(): session persisted: ", sessionData);

        // redirect to the application after token receiving
        window.location.href = DEFAULT_PAGE;
        return;
      } catch (err) {
        console.log("LoginPage.setSession() - error=", err);
      }
    }

    // we have not code or we have error during token receiving
    try {
      const sessionData = await sessionContext.getSession();
      console.log("LoginPage.setSession() - current session", { ...sessionData });

      // redirect to the application if we have saved token
      // TODO: check for expiration?
      if (sessionData && sessionData.AccessToken) {
        window.location.href = DEFAULT_PAGE;
        return;
      }
    } catch (err) {
      // TODO:  do this in a "React-friendly" way
      console.log("LoginPage.setSession() - error = ", err);
    }

    // no code and no saved session - open cognitio page
    window.location.href = CognitoHostedUILoginURL();
    //  https://dev-momento-operator.auth.us-west-2.amazoncognito.com/login?client_id=2c2l3f2728o74j32v1ba9b7p&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://mom-dev-operator.momento.pics/
  };

  useEffect(() => {
    setSession();
  }, []);

  return (
    <div style={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
      width: "100%",
      height: "100%",
      position: "absolute",
    }}
    >
      {
        parsedQueryParams[PARAMS.CODE]
          ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                position: "absolute",
                backgroundColor: COLORS.BLACK_MAIN,
                color: COLORS.WHITE,
              }}
            >
              { isAuthorized ? (
                <div>
                  <Spin size="large" />
                  <div>Loading...</div>
                </div>
              ) : (
                <div>
                  Sorry, your user is NOT AUTHORIZED for this site.
                  <br />
                  <br />
                  {(authorizedSites.length === 0) ? "" : "Sites that your user is authorized for:"}
                  <ul>
                    {
                      authorizedSites.map((site) => {
                        return (
                          <li>
                            <a href={getRedirectURL(site.title)}>
                              {site.title}
                              &nbsp;Operator
                            </a>
                          </li>
                        );
                      })
                    }
                  </ul>
                </div>
              )}
            </div>
          ) : (<div />)
      }
    </div>
  );
});

export default LoginPage;
