import { stringify } from "query-string";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import {
  GameInput, UpdateGameRequest, DocumentInput, OperatorGamePopulated,
  GetGameListRequest, SetFinishDateRequest, BasicResponse,
} from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getGamesPopulatedRequest = async (
  request: GetGameListRequest,
): Promise<OperatorGamePopulated[]> => {
  const params = stringify(request);

  try {
    const response = await instance.get(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}?${params}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getGameDataByIdRequest = async (gameId: string): Promise<OperatorGamePopulated> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${gameId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addGameRequest = async (request: GameInput): Promise<DocumentInput> => {
  try {
    const response = await instance.post<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameRequest = async (
  request: UpdateGameRequest, gameId: string,
): Promise<DocumentInput> => {
  try {
    const response = await instance.put<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${gameId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const deleteGameRequest = async (gameId: string): Promise<boolean> => {
  try {
    await instance.delete<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${gameId}`);

    return true;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const changeGameStatusRequest = async (
  request: SetFinishDateRequest, gameId: string,
): Promise<boolean> => {
  try {
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.STATUS}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameIsPaidGameRequest = async (
  isPaid: boolean, gameId: string,
): Promise<boolean> => {
  try {
    const request = {
      paidGame: isPaid || false,
    };
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.UPDATE_GAME_IS_PAID_GAME}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameShowPrintOrderButtonRequest = async (
  showPrintOrderButton: boolean, gameId: string,
): Promise<boolean> => {
  try {
    const request = {
      showPrintOrderButton: showPrintOrderButton || false,
    };
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.UPDATE_GAME_SHOW_PRINT_ORDER_BUTTON}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameCheckLastNameRequest = async (
  checkLastName: boolean, gameId: string,
): Promise<boolean> => {
  try {
    const request = {
      checkLastName: checkLastName || false,
    };
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.UPDATE_GAME_CHECK_LAST_NAME}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameTemplateRequest = async (
  ticketTemplate: string, gameId: string,
): Promise<boolean> => {
  try {
    const request = {
      ticketTemplate: ticketTemplate || null,
    };
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.UPDATE_GAME_TICKET_TEMPLATE}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateGameTemplatesRequest = async (
  ticketTemplate: string,
  fan1stTemplate: string,
  specialSectionTemplate: string,
  gameId: string,
): Promise<boolean> => {
  try {
    const request = {
      ticketTemplate: ticketTemplate || null,
      fan1stTicketTemplate: fan1stTemplate || null,
      specialSectionTemplate: specialSectionTemplate || null,
    };
    const response = await instance.put<BasicResponse>(`${PATH.API}/${PATH.OPERATOR}/${PATH.GAME}/${PATH.UPDATE_GAME_TICKET_TEMPLATES}/${gameId}`, request);

    return response.data.success;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
