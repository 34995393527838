// export enum Routes {
//   HOMEPAGE="/operator",
//   TEAMS="/operator/teams",
//   GAMES="/operator/games",
//   STADIUM="/operator/stadium",
//   MAPPING="/operator/mapping",
//   MOMENT="/operator/moment",
// }

export enum Routes {
  HOMEPAGE="/",
  LOGIN="/",
  TEAMS="/teams",
  GAMES="/games",
  STADIUM="/stadium",
  MAPPING="/mapping",
  MOMENT="/moment",
  USERS="/users",
  GAMESEATS="/gameseats",
  TEMPLATES="/templates",
  DOWNLOADS="/downloads",
}

export enum PARAMS {
  ID="id",
  GAME_ID="gameId",
  CODE="code",
  HOSTNAME_PREFIX="hostnamePrefix",
  STATE="state"
}
