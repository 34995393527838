import { stringify } from "query-string";
import { gunzipSync } from "zlib";
import { instance } from "../instance";
import { IAxiosError } from "../interfaces";
import { getError } from "../utils";

import {
  PersistedUser,
  UniqueUser,
  DocumentInput,
  GetUserListRequest,
  GetCognitoUserResponse,
  CognitoTokenResponse,
  CognitoTokenRefreshRequest,
  CognitoTokenRefreshResponse,
} from "../../shared/interfaces";
import { PATH } from "../../shared/constants";

export const getUserListRequest = async (request: GetUserListRequest): Promise<UniqueUser[]> => {
  const result : UniqueUser[] = [];
  const queryStringTemplate = request.games ? (`games=${request.games.join(",")}`) : "";
  let startIndexOfNextResultSet = null;

  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const queryString = (startIndexOfNextResultSet == null)
        ? queryStringTemplate
        : `${queryStringTemplate}&startIndex=${startIndexOfNextResultSet}`;

      // eslint-disable-next-line no-await-in-loop
      const response = await instance.get(`${PATH.API}/${PATH.USER}?${queryString}`);

      console.log(`getUserListRequest() - got response of length ${response.data.length}`);
      let partialResultSet : UniqueUser[] = null;
      if (response.headers["content-encoding"] === "gzip") {
        const decompressed = gunzipSync(response.data);
        partialResultSet = JSON.parse(decompressed.toString("utf8"));
      } else {
        partialResultSet = response.data;
      }

      // append the newly retrieved results to the resultset to be returned
      result.push(...partialResultSet);

      const lastUserInResultSet : any = result[result.length - 1];
      if ("unretrievedUsers" in lastUserInResultSet) {
        console.log(`getUserListRequest() - last user contains indicator that there are ${lastUserInResultSet.unretrievedUsers} more users in result set; making another API call to get the remainer...`);
        startIndexOfNextResultSet = result.length;
      } else {
        break;
      }
    }

    return result;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const getUserByIdRequest = async (userId: string): Promise<PersistedUser> => {
  try {
    const response = await instance.get(`${PATH.API}/${PATH.USER}/${userId}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const addUserRequest = async (request: FormData): Promise<DocumentInput> => {
  try {
    const response = await instance.post<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.USER}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const updateUserRequest = async (
  request: FormData, userId: string,
): Promise<DocumentInput> => {
  try {
    const response = await instance.put<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.USER}/${userId}`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const deleteUserRequest = async (userId: string): Promise<boolean> => {
  try {
    await instance.delete<DocumentInput>(`${PATH.API}/${PATH.OPERATOR}/${PATH.USER}/${userId}`);

    return true;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const exchangeCognitoCodeForTokensRequest = async (code?: string, redirect_uri?: string):
  Promise<CognitoTokenResponse> => {
  try {
    let paramsToAppend = "";
    if (code) {
      paramsToAppend = `cognitoCode=${code}`;
    }
    if (redirect_uri) {
      paramsToAppend = `${paramsToAppend}${paramsToAppend ? "&" : ""}redirect_uri=${redirect_uri}`;
    }

    const response: any = await instance.get(`${PATH.API}/${PATH.USER}/cognitoUser/tokens?${paramsToAppend}`);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};

export const refreshCognitoTokensRequest = async (request: CognitoTokenRefreshRequest):
  Promise<CognitoTokenRefreshResponse> => {
  try {
    const response: any = await instance.put(`${PATH.API}/${PATH.USER}/cognitoUser/tokens`, request);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(JSON.stringify(err));
  }
};

export const getCognitoUserRequest = async (emailAddress?: string, accessToken?: string):
  Promise<GetCognitoUserResponse> => {
  try {
    let paramsToAppend = "";
    if (emailAddress) {
      paramsToAppend = `emailAddress=${emailAddress}`;
    }
    if (accessToken) {
      paramsToAppend += `${(paramsToAppend.length === 0) ? "" : "&"}accessToken=${accessToken}`;
    }

    const config = {
      headers: {
        Authorization: `bearer ${accessToken}`,
      },
    };

    const response: any = await instance.get(`${PATH.API}/${PATH.USER}/cognitoUser?${paramsToAppend}`, config);

    return response.data;
  } catch (err) {
    const { response } = err as IAxiosError;
    throw new Error(getError(response));
  }
};
