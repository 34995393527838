import { createContext } from "react";
import { makeAutoObservable, observable } from "mobx";

import { HOUR, STORAGE, TEMP_TOKEN } from "../utils/constants";
import { setBearerToken } from "../axios/instance";
import { GetSportListRequest, NameInput } from "../shared/interfaces";
import { getSportListRequest, getStateListRequest } from "../axios/routes/sport";
import { NotificationServiceInstance } from "./notification";
import { SessionServiceInstance } from "./session";
import { UsersServiceInstance } from "./users";
import { Routes } from "../utils/routes";

export const NOT_LOADED_SPORT = "Sports data not loaded";

class AppService {
  @observable token = null;

  @observable lastCameraId: string = null;

  @observable lastCameraName: string = null;

  @observable mappingMarkRadius: number = null;

  @observable stateList: NameInput[] = [];

  @observable sportList: GetSportListRequest[] = [];

  constructor() {
    makeAutoObservable(this);

    // temporary token that is used before the login/logout features are implemented
    this.token = TEMP_TOKEN;

    setBearerToken(TEMP_TOKEN);
    SessionServiceInstance.getSession().then((sessionData) => {
      if (!sessionData) {
        console.log("no session", window.location.pathname);
        // TODO:  do this in a "React-friendly" way
        if (window.location.pathname !== Routes.LOGIN) {
          window.location.href = Routes.LOGIN;
        }
      } else {
        // do not use cognito token: backend does not support it
        // setBearerToken(sessionData.AccessToken);
        UsersServiceInstance.appTokenSet = true;
        this.getMappingCameraData();

        this.fetchStateList();
        this.fetchSportList();
      }
    }).catch((err) => {
      console.log("Error getting session:", err);
      // TODO:  do this in a "React-friendly" way
      if (window.location.pathname !== Routes.LOGIN) {
        window.location.href = Routes.LOGIN;
      }
    });
  }

  /**
   * The list of the states being used in filters and forms
   * that is fetched automatically on the app initialization
   *
   */
  private fetchStateList = async () => {
    try {
      this.stateList = await getStateListRequest();
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchStadiumList()", err.message);
    }
  }

  /**
   * The list of the sport being used in filters and forms
   * that is fetched automatically on the app initialization
   */
  private fetchSportList = async () => {
    try {
      this.sportList = await getSportListRequest();
    } catch (err) {
      NotificationServiceInstance.showUnexpectedErrorNotification("fetchSportList()", err.message);
    }
  }

  /**
   * Every game has duration limit. If limit is not reached or the game wasn't finished manually
   * it is an ongoing game.
   * @param sportId each sport has it's own duratio, need to provide sport id to determine status
   * @param startDate timestamp of the start of the game
   * @returns true if the game is still ongoing, otherwise false
   */
  public canBeOngoingGame = (sportId: string, startDate: number): boolean | string => {
    const targetSport = this.sportList.find((s) => s._id === sportId);
    if (!targetSport) {
      return NOT_LOADED_SPORT;
    }

    return startDate + targetSport.maxDuration > Date.now();
  }

  public getSportMaxDurationHours = (sportId: string): number | string => {
    const targetSport = this.sportList.find((s) => s._id === sportId);

    if (!targetSport) {
      return NOT_LOADED_SPORT;
    }

    return targetSport.maxDuration / HOUR;
  }

  /**
   * Saves the last camera that was mapped to be able to return to it later
   * @param id id of the camera
   * @param name name of the camera being mapped (for modal screen)
   */
  public saveMappingCameraData = (id: string, name: string) => {
    localStorage.setItem(STORAGE.CAMERA_ID, id);
    localStorage.setItem(STORAGE.CAMERA_NAME, name);

    this.lastCameraId = id;
    this.lastCameraName = name;

    console.log("> Camera data saved");
  }

  /**
   * Retrieves previously saved game data
   */
  public getMappingCameraData = () => {
    this.lastCameraId = localStorage.getItem(STORAGE.CAMERA_ID) || null;
    this.lastCameraName = localStorage.getItem(STORAGE.CAMERA_NAME) || null;

    if (this.lastCameraId) {
      console.log("> Camera data fetched");
    }
  }

  /**
   * Mark radius is a local variable that Operator can set
   * based on his screen size and resolution to make marks look better
   * @param radius new value that was set by operator
   */
  public saveMappingMarkRadius = (radius: number) => {
    localStorage.setItem(STORAGE.MARK_RADIUS, radius.toString());

    this.mappingMarkRadius = 7;

    console.log("> Mapping mark radius saved");
  }

  /**
   * Retrieves previously saved mark data
   */
  public getMappingMarkRadius = (): number|null => {
    const radius = localStorage.getItem(STORAGE.MARK_RADIUS);
    if (!radius) {
      return null;
    }

    console.log("> Mapping mark radius fetched");
    return Number(radius);
  }
}

export const AppServiceInstance = new AppService();

export const AppServiceContext = createContext(AppServiceInstance);
